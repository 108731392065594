import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './components/Auth/SignIn';
import SignUp from './components/Auth/SignUp';
import HomePage from './components/General/Home/Homepage';
import './App.css';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import ForgotPassword from './components/Auth/ForgotPassword';
import Settings from './components/General/Account/settings';
import Logs from './components/General/Account/Logs';
import SignupProvider from './components/Auth/SignupProvider';
import SignupStripe from './components/Auth/SignupStripe';
import LandingPage from './components/Website/LandingPage';
import PrivacyPolicyPage from './components/Website/PrivacyPolicy';
import TermsOfUsePage from './components/Website/Terms';
import SupportPage from './components/Website/Support';
import ScrollToTop from './ScrollToTop'; 
import Messages from './components/General/Messages';
import TechSpecs from './components/Website/TechSpecs';
import Pricing from './components/Website/Pricing';

import AI from './components/AI/AI'
import Teachingai from './components/AI/TeachingPage';
import SingleArticle from './components/Articles/SingleArticle';
import UploadArticle from './components/Articles/UploadArticle';
import Blog from './components/Articles/Blog';
import SearchResults from './components/Articles/SearchResults';
import ReactGA from "react-ga4";
import API from './components/General/API/API';
import useUID from './components/General/useUID';
import Users from './components/General/Users/Users';
import AddRpmCcmPatient from './components/PatientApp/RPMCCMPortal';

import PatientFiles from './components/PatientFiles/PatientFiles';
import AddPatientIntakeForm from './components/PatientFiles/Clinics/Intake/IntakeForm';


import Monitoring from './components/Monitoring/Monitoring_main';
import TerraSignIn from './components/PatientApp/Libre-Portal';
import AuthSuccess from './components/PatientApp/AuthSuccess';
import DexcomAuthSuccess from './components/PatientApp/AuthSuccessDexcom';

import PatientMonitoring from './components/Monitoring/PatientMonitoring';

function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  // useEffect(() => {
  //   // Send pageview with a custom path
  //   ReactGA.send({ hitType: "pageview", page:  window.location.pathname + window.location.search, title: "Page Hit" });

  //   // // Send a custom event
  //   // ReactGA.event({
  //   //   category: "your category",
  //   //   action: "your action",
  //   //   label: "your label", // optional
  //   //   value: 99, // optional, must be a number
  //   //   nonInteraction: true, // optional, true/false
  //   //   transport: "xhr", // optional, beacon/xhr/image
  //   // });
  // }, [])

  const signInSuccess = () => {
    setIsSignedIn(true);
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    const rootElement = document.documentElement;
    if (isDarkMode) {
      console.log("Switching to dark mode");
      rootElement.classList.add("dark-mode");
    } else {
      console.log("Switching to light mode");
      rootElement.classList.remove("dark-mode");
    }
  };
  
  
  
  return (
    <Router>
      <ScrollToTop />
      {isSignedIn ? (
        <Navigate to="/home" />
      ) : (
        <Routes>
          <Route path="/" element={< LandingPage />} />
          <Route path="/privacy" element={< PrivacyPolicyPage />} />
          <Route path="/terms" element={< TermsOfUsePage />} />
          <Route path="/support" element={< SupportPage />} />
          <Route path="/blog" element={< Blog />} />
          <Route path="/forgot-password" element={< ForgotPassword />} />
          <Route path="/signin" element={<SignIn onSignInSuccess={signInSuccess} />} />
          <Route path="/signup/:token?" element={<SignUp />} />
          <Route path="/signupprovider" element={<SignupProvider />} />
          <Route path="/payment" element={<SignupStripe />} />
          <Route path="/techspecs" element={<TechSpecs />} />
          <Route path="/pricing" element={<Pricing />} />
          

          <Route path="/home" element={<ProtectedRoute><HomePage onToggleTheme={toggleTheme} /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/logs" element={<ProtectedRoute><Logs /></ProtectedRoute>} />
          <Route path="/ai" element={<ProtectedRoute><AI /></ProtectedRoute>} />
          <Route path="/patients/:patientIdfromURL?" element={<ProtectedRoute><PatientFiles /></ProtectedRoute>} />
          <Route path="/patients/:PatientId/encounters" element={<ProtectedRoute><PatientFiles /></ProtectedRoute>} />
          <Route path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
          <Route path="/intake/:clinic" element={<AddPatientIntakeForm />} />
          <Route path="/1234dsasdfssdfjghjkgaerg123452134" element={<ProtectedRoute><Teachingai /></ProtectedRoute>} />
          <Route path="/article/:id" element={<SingleArticle />} />
          <Route path="/articleadd" element={<ProtectedRoute><UploadArticle /></ProtectedRoute>} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/messages" element={<ProtectedRoute><Messages /></ProtectedRoute>} />

          <Route path="/monitoring/:patientId" element={<ProtectedRoute><PatientMonitoring /></ProtectedRoute>} />
          <Route path="/cgm" element={<TerraSignIn />}/>
          <Route path="/intake" element={<AddRpmCcmPatient />}/>
          <Route path="/success" element={<AuthSuccess />} />
          <Route path="/successdexcom" element={<DexcomAuthSuccess />} />
          <Route path="/monitoring" element={<ProtectedRoute><Monitoring /></ProtectedRoute>} />

        </Routes>
      )}
    </Router>
  );
}

export default App;
