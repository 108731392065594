import { useState } from "react";

function CollapsibleSection({ title, number, children, openSections, setOpenSections }) {
  const isOpen = openSections.includes(number);

  function toggleSection() {
    if (isOpen) {
      setOpenSections(openSections.filter((section) => section !== number));
    } else {
      setOpenSections([...openSections, number]);
    }
  }

  return (
    <div className="collapsibleSection">
      <div className="sectionHeader" onClick={toggleSection}>
        <span className="sectionNumber">{number}.</span>
        <span className="sectionTitle">{title}</span>
        <span className="indicator">{isOpen ? "-" : "+"}</span>
      </div>
      {isOpen && <div className="sectionContent">{children}</div>}
    </div>
  );
}

export default CollapsibleSection;
