import React, { useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUndo, faPlus } from "@fortawesome/free-solid-svg-icons";

const ProviderSearch = ({ onProviderSelect }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null); // Track the selected provider
    const [manualEntry, setManualEntry] = useState(false); // Toggle for manual input mode

    // Manual input fields state
    const [manualProvider, setManualProvider] = useState({
        name: "",
        phone: "",
        address: "",
    });

    // Toggle manual input mode
    const toggleManualEntry = () => {
        setManualEntry(!manualEntry);
        setSearchResults([]);
        setSearchQuery("");
        setManualProvider({ name: "", phone: "", address: "" });
    };

    // Sanitize input to remove special characters and ensure uppercase
    const sanitizeInput = (input) => {
        return input.trim().toUpperCase().replace(/[^\w\s]/gi, "");
    };

    // Execute Firestore search for first name, last name, or organization name
    const executeSearch = async () => {
        const sanitizedQuery = sanitizeInput(searchQuery);
        if (!sanitizedQuery) {
            console.warn("Search query is empty after sanitization.");
            return;
        }
    
        console.log(`Executing search for query: "${sanitizedQuery}"`);
        setLoading(true);
        const results = new Map(); // Use a Map to keep unique results
    
        // Split the query into potential first and last names
        const [firstNameQuery, lastNameQuery] = sanitizedQuery.split(" ");
    
        try {
            // Query by Provider First Name
            const firstNameSearchQuery = query(
                collection(db, "npiproviderdata"),
                where("Provider First Name", ">=", firstNameQuery),
                where("Provider First Name", "<=", firstNameQuery + "\uf8ff")
            );
            const firstNameSnapshot = await getDocs(firstNameSearchQuery);
            firstNameSnapshot.forEach((doc) => {
                results.set(doc.id, { id: doc.id, ...doc.data() });
            });
    
            // Query by Provider Last Name
            const lastNameSearchQuery = query(
                collection(db, "npiproviderdata"),
                where("Provider Last Name", ">=", lastNameQuery || firstNameQuery),
                where("Provider Last Name", "<=", (lastNameQuery || firstNameQuery) + "\uf8ff")
            );
            const lastNameSnapshot = await getDocs(lastNameSearchQuery);
            lastNameSnapshot.forEach((doc) => {
                results.set(doc.id, { id: doc.id, ...doc.data() });
            });
    
            // Query by combined first and last names if both are provided
            if (firstNameQuery && lastNameQuery) {
                const combinedNameQuery = query(
                    collection(db, "npiproviderdata"),
                    where("Provider First Name", "==", firstNameQuery),
                    where("Provider Last Name", "==", lastNameQuery)
                );
                const combinedNameSnapshot = await getDocs(combinedNameQuery);
                combinedNameSnapshot.forEach((doc) => {
                    results.set(doc.id, { id: doc.id, ...doc.data() });
                });
            }
    
            // Query by Provider Organization Name
            const orgNameQuery = query(
                collection(db, "npiproviderdata"),
                where("Provider Organization Name", ">=", sanitizedQuery),
                where("Provider Organization Name", "<=", sanitizedQuery + "\uf8ff")
            );
            const orgNameSnapshot = await getDocs(orgNameQuery);
            orgNameSnapshot.forEach((doc) => {
                results.set(doc.id, { id: doc.id, ...doc.data() });
            });
    
            // Convert results to an array and set it to searchResults state
            setSearchResults(Array.from(results.values()));
            console.log(`Found ${results.size} unique providers matching query: "${sanitizedQuery}"`);
    
        } catch (error) {
            console.error("Error fetching providers:", error);
            alert("An error occurred while fetching providers. Please try again.");
        }
    
        setLoading(false);
    };
    

    // Handle provider selection
    const handleProviderClick = (provider) => {
        setSelectedProvider(provider); // Set the selected provider
        setSearchResults([]); // Clear search results
        setSearchQuery(""); // Clear search query
        onProviderSelect(provider); // Pass selected provider to parent component
    };

    // Handle changing the selection
    const handleChangeSelection = () => {
        setSelectedProvider(null); // Reset selected provider
        setSearchQuery(""); // Reset search query
    };

    // Handle manual provider submission
    const handleManualSubmit = () => {
        if (!manualProvider.name || !manualProvider.phone || !manualProvider.address) {
            alert("Please fill out all provider details.");
            return;
        }
        const provider = {
            "Provider Organization Name": manualProvider.name,
            "Phone Number": manualProvider.phone,
            "Address": { Street: manualProvider.address },
        };
        setSelectedProvider(provider);
        onProviderSelect(provider);
        setManualEntry(false);
    };

    return (
        <div className="provider-search">
            {!selectedProvider ? (
                <>
                    {manualEntry ? (
                        <div className="manual-input">
                            <input
                                type="text"
                                placeholder="Provider Name"
                                value={manualProvider.name}
                                onChange={(e) => setManualProvider({ ...manualProvider, name: e.target.value })}
                                required
                                className="manual-input-field"
                            />
                            <input
                                type="tel"
                                placeholder="Phone Number"
                                value={manualProvider.phone}
                                onChange={(e) => setManualProvider({ ...manualProvider, phone: e.target.value })}
                                required
                                className="manual-input-field"
                            />
                            <input
                                type="text"
                                placeholder="Address"
                                value={manualProvider.address}
                                onChange={(e) => setManualProvider({ ...manualProvider, address: e.target.value })}
                                required
                                className="manual-input-field"
                            />
                            <button onClick={handleManualSubmit} className="manual-submit-button">
                                <FontAwesomeIcon icon={faPlus} /> Add Provider
                            </button>
                            <button onClick={toggleManualEntry} className="toggle-manual-button">
                                <FontAwesomeIcon icon={faUndo} /> Back to Search
                            </button>
                        </div>
                    ) : (
                        <>
                           <input
                                type="text"
                                placeholder="Search by first name, last name, or organization name"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onKeyDown={(e) => e.key === 'Enter' && executeSearch()} // Trigger search on Enter
                                className="search-input"
                            />
                            <button onClick={executeSearch} className="search-button" disabled={loading}>
                                <FontAwesomeIcon icon={faSearch} /> Search
                            </button>
                            <button onClick={toggleManualEntry} className="toggle-manual-button">
                                <FontAwesomeIcon icon={faPlus} /> Add Manually
                            </button>
                            {loading && <p>Loading...</p>}

                            {/* Display search results */}
                            {searchResults.length > 0 && (
                                <ul className="search-results">
                                    {searchResults.map((provider) => (
                                        <li
                                            key={provider.id}
                                            onClick={() => handleProviderClick(provider)}
                                            className="search-result-item"
                                        >
                                            <div className="provider-name">
                                                {provider["Provider Organization Name"] || `${provider["Provider First Name"]} ${provider["Provider Last Name"]}`}
                                            </div>
                                            <div className="provider-details">
                                            {provider.Address?.Street}, {provider.Address?.City}, {provider.Address?.State} {provider.Address?.["Postal Code"]}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}
                </>
            ) : (
                <div className="provider-card">
                    <h4>Selected Provider</h4>
                    <p><strong>Name:</strong> {selectedProvider["Provider Organization Name"] || `${selectedProvider["Provider First Name"]} ${selectedProvider["Provider Last Name"]}`}</p>
                    <p><strong>Phone:</strong> {selectedProvider["Phone Number"]}</p>
                    <p><strong>Address:</strong> {selectedProvider.Address?.Street}, {selectedProvider.Address?.City}, {selectedProvider.Address?.State} {selectedProvider.Address?.["Postal Code"]}</p>
                    <button onClick={handleChangeSelection} className="change-selection-button">
                        <FontAwesomeIcon icon={faUndo} /> Change Selection
                    </button>
                </div>
            )}
        </div>
    );
};

export default ProviderSearch;
