import { auth, db, storage } from "../../firebase";
import React, { useState, useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { collection, addDoc, updateDoc, doc, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import HexagonSpinner from "../General/Animations/Hexspinner";
import InputMask from 'react-input-mask';
import PayerSelection from '../Payers/PayerSelection';
import ICD10 from '../PatientFiles/Encounters/ICD10';
import ProviderSearch from "./ProviderSearch";

const AddRpmCcmPatient = () => {
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganizationUid, setSelectedOrganizationUid] = useState(null);
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState("");
    const [selectedPayer, setSelectedPayer] = useState(null);
    const [patientType, setPatientType] = useState(null); 
    const [payers] = useState([]);
    const [memberId, setMemberId] = useState("");
    const [address, setAddress] = useState({
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
    });
    const [newPatient, setNewPatient] = useState({
        firstName: "",
        middleName: "", 
        lastName: "",
        dob: "",
        gender: "",
    });
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [file, setFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [signature, setSignature] = useState(null);
    const [loading, setLoading] = useState(false);
    const [diagnosisCode, setDiagnosisCode] = useState("");
    const [success, setSuccess] = useState(false); 
    const [formErrors, setFormErrors] = useState({}); 
    const [selectedProvider, setSelectedProvider] = useState(null);
    const sigPad = useRef(null); // Define sigPad as a ref

    useEffect(() => {
        const fetchOrganizations = async () => {
            const organizationsRef = collection(db, "users");
            const organizationDocs = await getDocs(organizationsRef);
            const filteredOrganizations = organizationDocs.docs.map(doc => ({ ...doc.data(), uid: doc.id }));

            setOrganizations(filteredOrganizations);
        };
        fetchOrganizations();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPatient((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const formatDate = (date) => {
        const [year, month, day] = date.split('-');
        return `${month}/${day}/${year}`;
    };

    const validateForm = () => {
        const errors = {};

        if (!selectedOrganizationUid) errors.organization = "Organization is required.";
        if (!newPatient.firstName) errors.firstName = "First name is required.";
        if (!newPatient.lastName) errors.lastName = "Last name is required.";
        if (!dob) errors.dob = "Date of birth is required.";
        if (!gender) errors.gender = "Gender is required.";
        if (!address.address1) errors.address1 = "Address line 1 is required.";
        if (!address.city) errors.city = "City is required.";
        if (!address.state) errors.state = "State is required.";
        if (!address.zip) errors.zip = "Zip code is required.";
        if (!email) errors.email = "Email is required.";
        if (!phone) errors.phone = "Phone number is required.";
        if (!memberId) errors.memberId = "Member ID is required.";
        if (!selectedPayer) errors.payer = "Payer selection is required.";
        if (!file) errors.file = "Insurance card photo is required.";
        if (sigPad.current.isEmpty()) errors.signature = "Signature is required.";
        if (!patientType) errors.patientType = "Patient type (RPM or CCM) is required.";
        if (!selectedProvider) errors.provider = "Provider selection is required."; // New validation for provider

        setFormErrors(errors);
        return Object.keys(errors).length === 0; 
    };

    console.log(selectedPayer)

    const handleAddPatient = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        if (!validateForm()) {
            setLoading(false);
            return;
        }
    
        const uid = selectedOrganizationUid;
        const patientsRef = collection(db, "patients", uid, "patientData");
        const formattedDob = formatDate(dob);
    
        // Include provider information in new patient data
        const newPatientData = {
            patient: {
                ...newPatient,
                dob: formattedDob,
                gender,
                address,
                email,
                phone,
            },
            payers: {
                name: selectedPayer.name, 
                memberId,
            },
            type: patientType,
            provider: selectedProvider
            ? {
                name: selectedProvider["Provider Organization Name"] || `${selectedProvider["Provider First Name"]} ${selectedProvider["Provider Last Name"]}`,
                phone: selectedProvider["Phone Number"],
                address: `${selectedProvider.Address?.Street}, ${selectedProvider.Address?.City}, ${selectedProvider.Address?.State} ${selectedProvider.Address?.["Postal Code"]}`,
                npi: selectedProvider["NPI"],
                taxonomyCode: selectedProvider["Taxonomy Code 1"],
            }
            : null        
        };
    
        try {
            const patientDoc = await addDoc(patientsRef, newPatientData);
            const patientId = patientDoc.id;
    
            // Save insurance card file
            const filePath = `PatientDocuments/${uid}/${file.name}`;
            const fileRef = ref(storage, filePath);
            await uploadBytes(fileRef, file);
            const downloadUrl = await getDownloadURL(fileRef);
            const filesRef = collection(db, "patients", uid, "patientData", patientId, "files");
            await addDoc(filesRef, { name: file.name, url: downloadUrl, timestamp: new Date() });
    
            // Save signature
            const signatureDataUrl = sigPad.current.toDataURL();
            const signatureBlob = await (await fetch(signatureDataUrl)).blob();
            const signatureFilePath = `PatientDocuments/${uid}/${patientId}_signature.png`;
            const signatureFileRef = ref(storage, signatureFilePath);
            await uploadBytes(signatureFileRef, signatureBlob);
            const signatureDownloadUrl = await getDownloadURL(signatureFileRef);
            await updateDoc(doc(db, "patients", uid, "patientData", patientId), { signature: signatureDownloadUrl });
    
            setSuccess(true); 
        } catch (error) {
            console.error("Error saving patient data:", error);
        } finally {
            setLoading(false);
        }
    };
    

    const handleFileChange = (file) => {
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onCameraClick = async () => {
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            const videoElement = document.getElementById('videoPreview');
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
                videoElement.srcObject = stream;
                videoElement.play();
                document.getElementById('captureButton').style.display = 'block';
            } catch (error) {
                alert("Couldn't access the camera. Please enable camera permissions in the browser settings.");
            }
        } else {
            document.getElementById("fileInput").click();
        }
    };

    const capturePhoto = () => {
        const videoElement = document.getElementById('videoPreview');
        const canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => {
            const capturedFile = new File([blob], "InsuranceCardfromIntake.jpeg", { type: "image/jpeg" });
            setFile(capturedFile);
            const dataURL = URL.createObjectURL(blob);
            setPreviewImage(dataURL);
        }, 'image/jpeg');
        videoElement.srcObject.getTracks().forEach(track => track.stop());
    };

    const handleDiagnosisCodeSelect = (selectedCode) => {
        setDiagnosisCode(selectedCode);
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <div className="add-patient-form">
            {success ? (
                <div className="success-message">
                    <h3>Patient added successfully!</h3>
                    <p>The RPM or CCM patient has been registered successfully.</p>
                    <button onClick={handleRefresh}>Add Another Patient</button>
                </div>
            ) : (
                <>
                    <h3 style={{ textAlign: "center" }}>Add RPM or CCM Patient</h3>

                    <div className="input-group">
                        <label htmlFor="organization">Organization</label>
                        <select
                            name="organization"
                            onChange={(e) => setSelectedOrganizationUid(e.target.value)}
                            required
                        >
                            <option value="">--Please choose an organization--</option>
                            {organizations.map(org => (
                                <option key={org.uid} value={org.uid}>{org.OrganizationName}</option>
                            ))}
                        </select>
                        {formErrors.organization && <span className="error">{formErrors.organization}</span>}
                    </div>

                    
             {/* Patient Type Selection */}
                <div className="input-group">
                    <div className="input-row">
                        <label>Select Patient Type</label>
                        <button
                            className={patientType === "RPM" ? "selected" : ""}
                            onClick={() => setPatientType("RPM")}
                        >
                            RPM
                        </button>
                        <button
                            className={patientType === "CCM" ? "selected" : ""}
                            onClick={() => setPatientType("CCM")}
                        >
                            CCM
                        </button>
                        <button
                            className={patientType === "CGM" ? "selected" : ""}
                            onClick={() => {
                                setPatientType("CGM");
                                window.location.href = "/cgm";
                            }}
                        >
                            CGM
                        </button>
                    </div>
                    {formErrors.patientType && <span className="error">{formErrors.patientType}</span>}
                </div>


                    <div className="input-group-row">
                        <div className="input-field">
                            <label htmlFor="firstName">First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={newPatient.firstName}
                                onChange={handleInputChange}
                                required
                            />
                            {formErrors.firstName && <span className="error">{formErrors.firstName}</span>}
                        </div>
                        <div className="input-field">
                            <label htmlFor="middleName">Middle Name (optional)</label>
                            <input
                                type="text"
                                name="middleName"
                                value={newPatient.middleName}
                                onChange={handleInputChange}
                                style={{minWidth: "5rem", width: "5rem"}}
                           />
                        </div>
                        <div className="input-field">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                value={newPatient.lastName}
                                onChange={handleInputChange}
                                required
                            />
                            {formErrors.lastName && <span className="error">{formErrors.lastName}</span>}
                        </div>
                    </div>

                    <div className="input-group">
                        <label htmlFor="dob">Date of Birth</label>
                        <input
                            type="date"
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                            required
                        />
                        {formErrors.dob && <span className="error">{formErrors.dob}</span>}
                        <div className="gender-buttons">
                            <button
                                type="button"
                                className={`gender-button ${gender === "M" ? "selected" : ""}`}
                                onClick={() => setGender("M")}
                                required
                            >
                                M
                            </button>
                            <button
                                type="button"
                                className={`gender-button ${gender === "F" ? "selected" : ""}`}
                                onClick={() => setGender("F")}
                                required
                            >
                                F
                            </button>
                        </div>
                        {formErrors.gender && <span className="error">{formErrors.gender}</span>}
                    </div>

                    <div className="input-group-row">
                        <div className="input-field">
                            <label htmlFor="address1">Address line 1</label>
                            <input
                                type="text"
                                name="address1"
                                value={address.address1}
                                onChange={(e) => setAddress({ ...address, address1: e.target.value })}
                                required
                            />
                            {formErrors.address1 && <span className="error">{formErrors.address1}</span>}
                        </div>
                        <div className="input-field">
                            <label htmlFor="address2">Address line 2 (optional)</label>
                            <input
                                type="text"
                                name="address2"
                                value={address.address2}
                                onChange={(e) => setAddress({ ...address, address2: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="input-group-row">
                        <div className="input-field">
                            <label htmlFor="city">City</label>
                            <input
                                type="text"
                                name="city"
                                value={address.city}
                                onChange={(e) => setAddress({ ...address, city: e.target.value })}
                                required
                            />
                            {formErrors.city && <span className="error">{formErrors.city}</span>}
                        </div>
                        <div className="input-field">
                            <label htmlFor="state">State</label>
                            <input
                                type="text"
                                name="state"
                                maxLength="2"
                                pattern="^[A-Z]{2}$"
                                value={address.state}
                                onChange={(e) => setAddress({ ...address, state: e.target.value })}
                                className="stateInput"
                                required
                            />
                            {formErrors.state && <span className="error">{formErrors.state}</span>}
                        </div>
                        <div className="input-field">
                            <label htmlFor="zip">Zip</label>
                            <input
                                type="text"
                                name="zip"
                                maxLength="5"
                                pattern="^\d{5}$"
                                value={address.zip}
                                onChange={(e) => setAddress({ ...address, zip: e.target.value })}
                                className="zipInput"
                                required
                            />
                            {formErrors.zip && <span className="error">{formErrors.zip}</span>}
                        </div>
                    </div>

                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        {formErrors.email && <span className="error">{formErrors.email}</span>}
                    </div>
                    <div className="input-group">
                        <label htmlFor="phone">Phone</label>
                        <InputMask
                            mask="(999) 999-9999"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="(000)-000-0000"
                            required
                        >
                            {(inputProps) => <input {...inputProps} type="text" name="phone" />}
                        </InputMask>
                        {formErrors.phone && <span className="error">{formErrors.phone}</span>}
                    </div>

                    <div className="input-group">
                        <label htmlFor="memberId">Member ID</label>
                        <input
                            type="text"
                            name="memberId"
                            value={memberId}
                            onChange={(e) => setMemberId(e.target.value)}
                            required
                        />
                        {formErrors.memberId && <span className="error">{formErrors.memberId}</span>}
                    </div>

                    <div className="input-group-payerSelection">
                        <PayerSelection
                            payers={payers}
                            onSelect={setSelectedPayer}
                            required
                            mode={'claimStatus'}
                            className="payerContainer"
                            selectedOrganizationUid={selectedOrganizationUid} // Pass the selected organization UID

                        />
                        {formErrors.payer && <span className="error">{formErrors.payer}</span>}
                    </div>

                    {/* Camera and image handling */}
                    <div className="input-group">
                        {(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) ? (
                            <>
                                {previewImage ? (
                                    <div className="preview-image-container">
                                        <img src={previewImage} id="previewImage" alt="Preview" />
                                        <button onClick={() => setPreviewImage(null)}>
                                            <FontAwesomeIcon icon={faCamera} />
                                            Retake Photo of Insurance ID Card
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <video id="videoPreview" width="320" height="240" autoPlay muted playsInline style={{ display: 'none' }}></video>
                                        <button id="startCameraButton" onClick={onCameraClick}>
                                            <FontAwesomeIcon icon={faCamera} />
                                            Take picture of Insurance ID Card
                                        </button>
                                        <button id="captureButton" onClick={capturePhoto} style={{ display: 'none' }}>
                                            Capture
                                        </button>
                                    </>
                                )}
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    capture="camera"
                                    onChange={(e) => {
                                        if (e.target.files && e.target.files.length > 0) {
                                            handleFileChange(e.target.files[0]);
                                        }
                                    }}
                                    required
                                />
                            </>
                        ) : (
                            <>
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    capture="camera"
                                    onChange={(e) => {
                                        if (e.target.files && e.target.files.length > 0) {
                                            handleFileChange(e.target.files[0]);
                                        }
                                    }}
                                    required
                                />
                                <button onClick={onCameraClick} className="cameraButton">
                                    <FontAwesomeIcon icon={faCamera} />
                                    {file ? ' Change Photo' : ' Take/Select Photo of Insurance Card'}
                                </button>
                            </>
                        )}
                        {formErrors.file && <span className="error">{formErrors.file}</span>}
                    </div>

                    {/* <ICD10
                        onCodeSelect={handleDiagnosisCodeSelect}
                        initialSelectedCodes={
                            Array.isArray(diagnosisCode) ? diagnosisCode : ""
                        }
                    /> */}

                <h3>Please add you Primary Care Provider</h3>
             {/* Provider Search Component */}
            <ProviderSearch onProviderSelect={setSelectedProvider} />
            {formErrors.provider && <span className="error">{formErrors.provider}</span>} {/* Provider error display */}

             {/* Legal disclaimers */}
                    <div className="legal-disclaimer">
                        <p className="sign-up-legal-disclaimer">
                            <strong>Summary:</strong> By signing below, you agree to participate in Transglobal Care's remote patient monitoring and chronic care management programs. This includes the release of your health records for monitoring, usage of provided medical devices, and sharing your health data with involved healthcare providers. You can withdraw your consent at any time.

                            <br/><br/>

                            <strong>REMOTE PATIENT MONITORING AGREEMENT</strong><br/>
                            You authorize Transglobal Care to access and monitor your health records using remote patient monitoring services. Your data will be used to monitor your health, provide timely alerts, and communicate with you about your health status. Your records will be kept confidential.

                            <br/><br/>

                            <strong>CONSENT TO RELEASE HEALTH RECORDS</strong><br/>
                            By signing this form, you consent to release your health records to Transglobal Care for the purposes of remote patient monitoring and chronic care management. Your records will be used confidentially for these purposes.

                            <br/><br/>

                            <strong>REMOTE PATIENT MONITORING SERVICES</strong><br/>
                            Purpose: Monitor your health, identify changes, and provide insights.<br/>
                            Information Collected: Personal and health information, including medical history and current health status.<br/>
                            Usage: Monitor health, provide alerts, communicate status, and improve services.<br/>
                            Disclosure: Information may be shared with third-party service providers and government agencies as required by law.<br/>
                            Rights: You can access, review, correct, restrict use, or revoke consent for your information at any time.<br/>
                            Charges: No charges without prior consent. If insurance does not cover 100% of the service, you may be liable for a copay. CMG will notify you before any charges.

                            <br/><br/>

                            <strong>CHRONIC CARE MANAGEMENT AGREEMENT</strong><br/>
                            By signing, you agree to participate in the Chronic Care Management program. This program helps manage chronic conditions with a comprehensive care plan and dedicated care team. Your insurance may be billed for these services, and you can only have one physician managing these services at a time. You can withdraw from the program at any time.

                            <br/><br/>

                            By signing below, you acknowledge that you have read and understand this consent form and agree to participate in Convenient Monitoring Group's programs.
                        </p>
                    </div>

                    <div className="signature-pad">
                        <div style={{ padding: ".5rem" }}>
                            <h3>Please sign if you consent to the above:</h3>
                        </div>
                        <SignatureCanvas
                            ref={sigPad}
                            penColor="black"
                            canvasProps={{ className: 'sigCanvas' }}
                        />
                        <button onClick={() => sigPad.current.clear()}>
                            Clear
                        </button>
                        {formErrors.signature && <span className="error">{formErrors.signature}</span>}
                    </div>

                    {loading ? <HexagonSpinner /> : (
                        <div className="addPatientButton">
                            <button onClick={handleAddPatient} disabled={loading}>
                                Add Patient
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default AddRpmCcmPatient;
