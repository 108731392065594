import React from 'react';

const ValidationSelect = ({ selectedValidation, onValidationChange }) => {
  return (
    <>
      <label className="mt-3">Validation:</label>
      <select 
        id="validation" 
        className="ml-3" 
        value={selectedValidation}
        onChange={onValidationChange}
      >
        <option value="None">None</option>
        <option value="Alphabetic">Alphabetic</option>
        <option value="AlphaNumeric">Alphanumeric</option>
        <option value="Numeric">Numeric</option>
        <option value="Phone">Phone</option>
        <option value="Email">Email</option>
      </select>
    </>
  );
};

export default ValidationSelect;