import React from 'react';
import styles from './Hex.modules.css';

const Hex = ({ children, side, ...rest }) => {

  return (
    <div className="hexagonWrapper">
      <div className="hexagonShadowWrapper"> 
        <button 
          type="button" 
          className="hexagonButton"
          {...rest}
        >
          <div className={styles.hexIcon}>
            {children}
          </div>
        </button>
      </div>
    </div>
  );
};

export default Hex;
