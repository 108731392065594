import React, { useState, useEffect } from "react";
import { collection, addDoc, updateDoc, doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import './AddPatient.modules.css';
import { db } from "../../../firebase";
import InputMask from 'react-input-mask';
import useUID from '../../General/useUID';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import HexagonSpinner from "../../General/Animations/Hexspinner";
import PayerSelection from '../../Payers/PayerSelection';

function AddPatientPopup({ onClose, selectedFolder, onDataUpdated }) {
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [memberId, setMemberId] = useState("");
  const [address, setAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [newPatient, setNewPatient] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: "",
  });
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [patientNote, setPatientNote] = useState("");
  const [errors, setErrors] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentPatientId, setCurrentPatientId] = useState(null);
  const [selectedSecondaryPayer, setSelectedSecondaryPayer] = useState(null);
  const [secondaryMemberId, setSecondaryMemberId] = useState("");
  const [showSecondaryPayer, setShowSecondaryPayer] = useState(false);

  const navigate = useNavigate();
  const [uid] = useUID();

  useEffect(() => {
    if (!uid) return;
    const fetchUserData = async () => {
      const userDocRef = doc(db, "users", uid);
      try {
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    };

    fetchUserData();
  }, [uid]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPatient((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePayerSelect = (payer) => {
    console.log("Selected payer:", payer); 
    setSelectedPayer(payer);
  };

  const validate = () => {
    const errors = {};
    if (!newPatient.firstName) errors.firstName = "First name is required.";
    if (!newPatient.lastName) errors.lastName = "Last name is required.";
    if (!dob) errors.dob = "Date of birth is required.";
    if (!gender) errors.gender = "Gender is required.";
    if (memberId && !selectedPayer) errors.payer = "Please select a payer.";
    if (selectedPayer && !memberId) errors.memberId = "Please enter member ID.";

    
    if (showSecondaryPayer && ((secondaryMemberId && !selectedSecondaryPayer) || (!secondaryMemberId && selectedSecondaryPayer))) {
      errors.secondaryMemberId = "Both Secondary Member ID and Secondary Payer must be provided together.";
      errors.secondaryPayer = "Both Secondary Member ID and Secondary Payer must be provided together.";
  }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAddPatient = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
  
    if (!validate()) {
      setIsProcessing(false);
      return;
    }
  
    try {
      const formattedDob = formatDate(dob);
      const newPatientData = {
        patient: {
          ...newPatient,
          dob: formattedDob,
          gender,
          address,
          email,
          phone,
          ...(selectedFolder && selectedFolder.id ? { clinicToken: selectedFolder.id } : {}),
        },
        payers: {
         name: selectedPayer.name || '',
                memberId: memberId || '',
                ...selectedPayer,
  
          // Secondary payer information (only if both payer and member ID are provided)
          secondaryPayer: selectedSecondaryPayer && secondaryMemberId
            ? {
                name: selectedSecondaryPayer.name || '',
                memberId: secondaryMemberId || '',
                ...selectedSecondaryPayer,
              }
            : null,
        },
      };
  
      // Add patient data to Firestore
      const patientDoc = await addDoc(collection(db, "patients", uid, "patientData"), newPatientData);
      setCurrentPatientId(patientDoc.id);
  
      // Trigger data update
      if (onDataUpdated) onDataUpdated();
  
      // Navigate to the new patient's profile
      navigate(`/patients/${patientDoc.id}`);
  
      // Refresh the page after navigation
      window.location.reload();
    } catch (error) {
      console.error("Error adding patient:", error);
    } finally {
      setIsProcessing(false);
    }
  };
  
  
  // Function to handle secondary payer selection
const handleSecondaryPayerSelect = (payer) => {
  setSelectedSecondaryPayer(payer);
};

  

  const formatDate = (date) => {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  };

  return (
    <div className="add-patient-popup">
      <div className="add-patient-form">
        <div className="centerHeader">
          <h3>Add Patient</h3>
        </div>
        {selectedFolder && selectedFolder.name && selectedFolder.name !== "All Patients" && (
          <p>Adding patient to {selectedFolder.name} clinic.</p>
        )}

        {/* Personal Information */}
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="firstName">First Name</label>
            <input
              id="firstName"
              type="text"
              name="firstName"
              value={newPatient.firstName}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="input-field">
            <label htmlFor="middleName">Middle Name (optional)</label>
            <input
              id="middleName"
              type="text"
              name="middleName"
              value={newPatient.middleName}
              onChange={handleInputChange}
              style={{ width: "5rem", minWidth: "5rem" }}
            />
          </div>

          <div className="input-field">
            <label htmlFor="lastName">Last Name</label>
            <input
              id="lastName"
              type="text"
              name="lastName"
              value={newPatient.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        {/* Date of Birth and Gender */}
        <div className="input-group">
          <label htmlFor="dob">Date of Birth</label>
          <input
            id="dob"
            type="date"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
          />
          <div className="gender-buttons">
            <button
              id="genderMale"
              type="button"
              className={`gender-button ${gender === "M" ? "selected" : ""}`}
              onClick={() => setGender("M")}
            >
              M
            </button>
            <button
              id="genderFemale"
              type="button"
              className={`gender-button ${gender === "F" ? "selected" : ""}`}
              onClick={() => setGender("F")}
            >
              F
            </button>
          </div>
        </div>

        {/* Address */}
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="address1">Address line 1</label>
            <input
              id="address1"
              type="text"
              name="address1"
              value={address.address1}
              onChange={(e) =>
                setAddress({ ...address, address1: e.target.value })
              }
            />
          </div>
          <div className="input-field">
            <label htmlFor="address2">Address line 2</label>
            <input
              id="address2"
              type="text"
              name="address2"
              value={address.address2}
              onChange={(e) =>
                setAddress({ ...address, address2: e.target.value })
              }
            />
          </div>
        </div>
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="city">City</label>
            <input
              id="city"
              type="text"
              name="city"
              value={address.city}
              onChange={(e) => setAddress({ ...address, city: e.target.value })}
            />
          </div>

          <div className="input-field">
            <label htmlFor="state">State</label>
            <select
              id="state"
              name="state"
              value={address.state}
              onChange={(e) => setAddress({ ...address, state: e.target.value })}
              required
            >
              <option value="">Select a State</option>
              {['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'].map(s => (
                <option key={s} value={s}>{s}</option>
              ))}
            </select>
          </div>

          <div className="input-field">
            <label htmlFor="zip">Zip</label>
            <input
              id="zip"
              type="text"
              name="zip"
              maxLength="5"
              pattern="^\\d{5}$"
              title="ZIP should be exactly 5 digits."
              value={address.zip}
              onChange={(e) => setAddress({ ...address, zip: e.target.value })}
              className="zipInput"
            />
          </div>
        </div>

        {/* Email and Phone */}
        <div className="input-group-row">
          <div className="input-field">
            <label htmlFor="email">Email (optional)</label>
            <input
              id="email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-field">
            <label htmlFor="phone">Phone (optional)</label>
            <InputMask
              id="phone"
              mask="(999) 999-9999"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>

        {/* Notes */}
        <div className="input-group">
          <label htmlFor="patientNote">Notes</label>
          <textarea
            id="patientNote"
            name="patientNote"
            value={patientNote}
            onChange={(e) => setPatientNote(e.target.value)}
            placeholder="Enter your note"
          ></textarea>
        </div>

        {/* Member ID and Payer Selection */}
        <div className="input-group">
          <label htmlFor="memberId">Member ID</label>
          <input
            id="memberId"
            type="text"
            name="memberId"
            value={memberId}
            onChange={(e) => {
              const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
              setMemberId(sanitizedValue);
            }}
          />
        </div>

        {/* Payer Selection */}
        <div className="input-group-payerSelection">
          <PayerSelection onSelect={handlePayerSelect} required mode={'claimStatus'} />

        {!showSecondaryPayer && (
            <button type="button" className="secondaryButton" onClick={() => setShowSecondaryPayer(true)}>
              + Add Secondary Payer
            </button>
          )}
          
          {showSecondaryPayer && (
            <div>
              
              <div className="input-group">
                <label htmlFor="secondaryMemberId">Secondary Member ID</label>
                <input
                  id="secondaryMemberId"
                  type="text"
                  name="secondaryMemberId"
                  value={secondaryMemberId}
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Remove anything that's not a letter or a number
                    setSecondaryMemberId(sanitizedValue);
                  }}
                />
              </div>
              <PayerSelection onSelect={handleSecondaryPayerSelect} mode={'claims'} />

            </div>
          )}
        </div>


        {/* Display errors */}
        <div className="content-center">
          {errors.firstName && <p className="error">{errors.firstName}</p>}
          {errors.lastName && <p className="error">{errors.lastName}</p>}
          {errors.dob && <p className="error">{errors.dob}</p>}
          {errors.gender && <p className="error">{errors.gender}</p>}
          {errors.payer && <p className="error">{errors.payer}</p>}
          {errors.memberId && <p className="error">{errors.memberId}</p>}
        </div>

        {/* Add Patient Button */}
        {!isProcessing && (
          <div className="addPatientButton">
            <button type='submit' onClick={handleAddPatient} className="primary">
              <FontAwesomeIcon icon={faUserPlus} />  Add Patient
            </button>
          </div>
        )}

        {/* Loading Spinner */}
        {isProcessing && <HexagonSpinner />}
      </div>
    </div>
  );
}

export default AddPatientPopup;
