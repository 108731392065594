import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward, faReceipt, faCheckDouble, faBan, faSearch, faDivide, faInfoCircle, faHourglassHalf, faMoneyBillWave, faTimesCircle, faSpinner, faExclamationCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

// This object maps status category codes to their corresponding titles.
const statusCodes = {
  "A0": {
    title: "Forwarded",
    description: "Acknowledgement/Forwarded-The claim/encounter has been forwarded to another entity."
  },
  "A1": {
    title: "Received",
    description: "Acknowledgement/Receipt-The claim/encounter has been received. This does not mean that the claim has been accepted for adjudication."
  },
  "A2": {
    title: "Accepted",
    description: "Acknowledgement/Acceptance into adjudication system-The claim/encounter has been accepted into the adjudication system."
  },
  "A3": {
    title: "Unprocessable",
    description: "Acknowledgement/Returned as unprocessable claim-The claim/encounter has been rejected and has not been entered into the adjudication system."
  },
  "A4": {
    title: "Not Found",
    description: "Acknowledgement/Not Found-The claim/encounter cannot be found in the adjudication system."
  },
  "A5": {
    title: "Split",
    description: "Acknowledgement/Split Claim-The claim/encounter has been split upon acceptance into the adjudication system."
  },
  "A6": {
    title: "Missing Information",
    description: "Acknowledgement/Rejected for Missing Information - The claim/encounter is missing the information specified in the Status details and has been rejected."
  },
  "A7": {
    title: "Invalid Information",
    description: "Acknowledgement/Rejected for Invalid Information - The claim/encounter has invalid information as specified in the Status details and has been rejected."
  },
  "A8": {
    title: "Field Error",
    description: "Acknowledgement/Rejected for relational field in error."
  },
  "DR01": {
    title: "Receipt",
    description: "Acknowledgement/Receipt - The claim/encounter has been received. This does not mean the claim has been accepted into the data reporting/processing system."
  },
  "DR02": {
    title: "Data Acceptance",
    description: "Acknowledgement/Acceptance into the data reporting/processing system - The claim/encounter has been accepted into the data reporting/processing system."
  },
  "DR03": {
    title: "Data Unprocessable",
    description: "Acknowledgement/Returned as unprocessable claim - The claim/encounter has been rejected and has not been entered into the data reporting/processing system."
  },
  "DR04": {
    title: "Data Not Found",
    description: "Acknowledgement/Not Found - The claim/encounter cannot be found in the data reporting/processing system."
  },
  "DR05": {
    title: "Data Missing Information",
    description: "Acknowledgement/Rejected for Missing Information - The claim/encounter is missing the information specified in the Status details and has been rejected."
  },
  "DR06": {
    title: "Data Invalid Information",
    description: "Acknowledgment/Rejected for invalid information - The claim/encounter has invalid information as specified in the Status details and has been rejected."
  },
  "DR07": {
    title: "Data Field Error",
    description: "Acknowledgement/Rejected for relational field in error."
  },
  "DR08": {
    title: "Data Warning",
    description: "Acknowledgement/Warning - The claim/encounter has been accepted into the data reporting/processing system but has received a warning as specified in the Status details."
  },
  "P0": {
    title: "Pending Details",
    description: "Pending: Adjudication/Details-This is a generic message about a pended claim."
  },
  "P1": {
    title: "In Process",
    description: "Pending/In Process-The claim or encounter is in the adjudication system."
  },
  "P2": {
    title: "Payer Review",
    description: "Pending/Payer Review-The claim/encounter is suspended and is pending review (e.g. medical review, repricing, Third Party Administrator processing)."
  },
  "P3": {
    title: "Provider Info Requested",
    description: "Pending/Provider Requested Information - The claim or encounter is waiting for information that has already been requested from the provider."
  },
  "P4": {
    title: "Patient Info Requested",
    description: "Pending/Patient Requested Information - The claim or encounter is waiting for information that has already been requested from the patient."
  },
  "P5": {
    title: "Administrative Hold",
    description: "Pending/Payer Administrative/System hold"
  },
  "F0": {
    title: "Finalized",
    description: "Finalized-The claim/encounter has completed the adjudication cycle and no more action will be taken."
  },
  "F1": {
    title: "Paid",
    description: "Finalized/Payment-The claim/line has been paid."
  },
  "F2": {
    title: "Denied",
    description: "Finalized/Denial-The claim/line has been denied."
  },
  "F3": {
    title: "Revised",
    description: "Finalized/Revised - Adjudication information has been changed"
  },
  "F3F": {
    title: "Forwarded",
    description: "Finalized/Forwarded-The claim/encounter processing has been completed. Any applicable payment has been made and the claim/encounter has been forwarded to a subsequent entity as identified on the original claim or in this payer's records."
  },
  "F3N": {
    title: "Not Forwarded",
    description: "Finalized/Not Forwarded-The claim/encounter processing has been completed. Any applicable payment has been made. The claim/encounter has not been forwarded to a subsequent entity. This could be due to a lack of forwarding information or the payer has not contracted with a subsequent entity."
  },
  "F4": {
    title: "Adjudication Complete",
    description: "Finalized/Adjudication Complete - Not forwarded."
  },
  "R0": {
    title: "General Request",
    description: "Requests for additional Information/General Requests-Requests that don't fall into other R-type categories."
  },
  "R1": {
    title: "Specific Detail",
    description: "Request for Additional Information/More Specific Detail-Requesting additional information or more specific information."
  },
  "R2": {
    title: "Under Review",
    description: "Request for Additional Information/Under Review-Request for additional information will be forwarded upon completion of internal review process."
  },
  "R3": {
    title: "Provider Non-Response",
    description: "Request for Additional Information/Provider does not wish to respond to the payer’s request for additional information."
  },
  "R4": {
    title: "Forwarded Claim",
    description: "Request for Additional Information/Not our claim, forwarded to additional payer(s)."
  },
  "R5": {
    title: "Detailed Request",
    description: "Request for Additional Information/Request for additional information. More detailed explanation of what information is needed."
  },
  "R6": {
    title: "Provider Forwarding",
    description: "Requests for additional information have been received and have been or will be forwarded to the provider."
  },
  "R7": {
    title: "Patient Forwarding",
    description: "Requests for additional information have been received and have been or will be forwarded to the patient."
  },
  "R8": {
    title: "Complete Bill",
    description: "Request for Additional Information/Complete Bill Needed-Requesting that the complete bill be submitted."
  },
  "R9": {
    title: "Itemized Bill",
    description: "Request for Additional Information/Itemized Bill Needed-Requesting that an itemized bill be submitted."
  },
  "R10": {
    title: "Billing for Denial",
    description: "Request for Additional Information/Billing for Denial-Claim should be processed for denial. The provider expects that the patient should not be billed."
  },
  "R11": {
    title: "More Info Needed",
    description: "Request for Additional Information/More Information is Needed to Process Claim."
  },
  "R12": {
    title: "Pre-existing Review",
    description: "Request for Additional Information/Claim is being reviewed for potential pre-existing condition(s)."
  },
  "R13": {
    title: "Decertification Delay",
    description: "Request for Additional Information/Delayed, waiting for decertification process."
  },
  "R14": {
    title: "Original Rejection",
    description: "Request for Additional Information/Original Claim Rejected/Denied due to the information requested."
  },
  "R15": {
    title: "Partial Info Received",
    description: "Request for Additional Information/Only a portion of the information requested has been received."
  },
  "R16": {
    title: "Stop Loss Review",
    description: "Request for Additional Information/Claim is being reviewed for potential stop loss thresholds."
  },
  "E0": {
    title: "Response Error",
    description: "Response not possible - error on submitted request data"
  },
  "D0": {
    title: "Data Search Unsuccessful",
    description: "Data Search Unsuccessful - The payer is unable to return status on the requested claim(s) based on the submitted search criteria."
  }
  };
  
// Mapping of status category titles to FontAwesome icons
const statusIcons = {
  "Forwarded": faForward,             // Icon for Forwarded
  "Received": faReceipt,              // Icon for Received
  "Accepted": faCheckDouble,          // Icon for Accepted
  "Unprocessable": faBan,             // Icon for Unprocessable
  "Not Found": faSearch,              // Icon for Not Found
  "Split": faDivide,                  // Icon for Split
  "Missing Information": faInfoCircle,// Icon for Missing Information
  "Invalid Information": faTimesCircle,// Icon for Invalid Information
  "Field Error": faExclamationCircle, // Icon for Field Error
  "Receipt": faReceipt,               // Icon for Receipt
  "Data Acceptance": faCheckDouble,   // Icon for Data Acceptance
  "Data Unprocessable": faBan,        // Icon for Data Unprocessable
  "Data Not Found": faSearch,         // Icon for Data Not Found
  "Data Missing Information": faInfoCircle,// Icon for Data Missing Information
  "Data Invalid Information": faTimesCircle,// Icon for Data Invalid Information
  "Data Field Error": faExclamationCircle,// Icon for Data Field Error
  "Data Warning": faExclamationCircle,// Icon for Data Warning
  "Pending Details": faHourglassHalf, // Icon for Pending Details
  "In Process": faSpinner,            // Icon for In Process
  "Payer Review": faSearch,           // Icon for Payer Review
  "Provider Info Requested": faQuestionCircle,// Icon for Provider Info Requested
  "Patient Info Requested": faQuestionCircle,// Icon for Patient Info Requested
  "Administrative Hold": faHourglassHalf,// Icon for Administrative Hold
  "Finalized": faCheckDouble,         // Icon for Finalized
  "Paid": faMoneyBillWave,            // Icon for Paid
  "Denied": faTimesCircle,            // Icon for Denied
  "Revised": faExclamationCircle,     // Icon for Revised
  "Forwarded": faForward,             // Icon for Forwarded
  "Not Forwarded": faBan,             // Icon for Not Forwarded
  "Adjudication Complete": faCheckDouble,// Icon for Adjudication Complete
  "General Request": faQuestionCircle,// Icon for General Request
  "Specific Detail": faInfoCircle,    // Icon for Specific Detail
  "Under Review": faSpinner,          // Icon for Under Review
  "Provider Non-Response": faTimesCircle,// Icon for Provider Non-Response
  "Forwarded Claim": faForward,       // Icon for Forwarded Claim
  "Detailed Request": faInfoCircle,   // Icon for Detailed Request
  "Provider Forwarding": faForward,   // Icon for Provider Forwarding
  "Patient Forwarding": faForward,    // Icon for Patient Forwarding
  "Complete Bill": faReceipt,         // Icon for Complete Bill
  "Itemized Bill": faReceipt,         // Icon for Itemized Bill
  "Billing for Denial": faTimesCircle,// Icon for Billing for Denial
  "More Info Needed": faQuestionCircle,// Icon for More Info Needed
  "Pre-existing Review": faSpinner,   // Icon for Pre-existing Review
  "Decertification Delay": faHourglassHalf,// Icon for Decertification Delay
  "Original Rejection": faTimesCircle,// Icon for Original Rejection
  "Partial Info Received": faInfoCircle,// Icon for Partial Info Received
  "Stop Loss Review": faSearch,       // Icon for Stop Loss Review
  "Response Error": faExclamationCircle,// Icon for Response Error
  "Data Search Unsuccessful": faSearch,// Icon for Data Search Unsuccessful
};

// Update ClaimStatusCategoryFlag component
const ClaimStatusCategoryFlag = ({ statusCategoryCode }) => {
  const code = statusCategoryCode ? statusCategoryCode : '';
  const status = code ? (statusCodes[code] || {}) : {};
  const icon = statusIcons[status.title || '-'];

  return (
    <span>
      {icon && <FontAwesomeIcon icon={icon} />} {status.title || '-'}
    </span>
  );
};

export default ClaimStatusCategoryFlag;
