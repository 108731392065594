import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCapsules,
    faTv,
    faSchool,
    faHouseUser,
    faClinicMedical,
    faHospitalAlt,
    faHospitalUser,
    faPray,
    faBuilding,
    faLaptopMedical,
    faUserMd,
    faHome,
    faUsers,
    faCaravan,
    faBus,
    faBed,
    faShoppingBasket,
    faBriefcaseMedical,
    faStethoscope,
    faAmbulance,
    faHelicopter,
    faBabyCarriage,
    faShip,
    faUserNurse,
    faUserGraduate,
    faBrain,
    faSyringe,
    faToiletPaper,
    faVial,
    faHandHoldingMedical,
    faMicroscope,
    faQuestionCircle,
    faWheelchair
} from '@fortawesome/free-solid-svg-icons';

const posCodeToIcon = {
    "01": faCapsules,
    "02": faTv,
    "03": faSchool,
    "04": faHouseUser,
    "05": faClinicMedical,
    "06": faClinicMedical,
    "07": faClinicMedical,
    "08": faClinicMedical,
    "09": faBuilding,
    "10": faLaptopMedical,
    "11": faUserMd,
    "12": faHome,
    "13": faUsers,
    "14": faCaravan,
    "15": faBus,
    "16": faBed,
    "17": faShoppingBasket,
    "18": faBriefcaseMedical,
    "19": faHospitalAlt,
    "20": faStethoscope,
    "21": faHospitalUser,
    "22": faHospitalAlt,
    "23": faAmbulance,
    "24": faClinicMedical,
    "25": faBabyCarriage,
    "26": faShip,
    "31": faUserNurse,
    "32": faUserNurse,
    "33": faToiletPaper,
    "34": faPray,
    "41": faAmbulance,
    "42": faHelicopter,
    "49": faClinicMedical,
    "50": faHandHoldingMedical,
    "51": faHospitalAlt,
    "52": faHospitalAlt,
    "53": faBrain,
    "54": faUserGraduate,
    "55": faBrain,
    "56": faUserGraduate,
    "57": faSyringe,
    "58": faVial,
    "60": faSyringe,
    "61": faWheelchair,
    "62": faUserMd,
    "65": faHandHoldingMedical,
    "71": faClinicMedical,
    "72": faClinicMedical,
    "81": faMicroscope,
    "99": faQuestionCircle,
};

const POSCodeIcon = ({ posCode }) => {
    const icon = posCodeToIcon[posCode] || faQuestionCircle; // Default icon if POS code is not found
    return <FontAwesomeIcon icon={icon} />;
};

export default POSCodeIcon;
