import React, { useState, useRef, useEffect } from 'react';
import styles from './quicksearch.module.css'

const ServiceTypeDropdown = ({ onServiceTypeChange }) => {
    // List of service type codes and their descriptions
  const initialServiceTypeCodes = [
    { code: "1", description: "Medical Care" },
    { code: "2", description: "Surgical" },
    { code: "3", description: "Consultation" },
    { code: "4", description: "Diagnostic X-Ray" },
    { code: "5", description: "Diagnostic Lab" },
    { code: "6", description: "Radiation Therapy" },
    { code: "7", description: "Anesthesia" },
    { code: "8", description: "Surgical Assistance" },
    { code: "9", description: "Hearing Aid" },
    { code: "10", description: "Blood" },
    { code: "11", description: "Durable Medical Equipment Used" },
    { code: "12", description: "Durable Medical Equipment Purchased" },
    { code: "13", description: "Hearing Aid Power Source" },
    { code: "14", description: "Renal Supplies" },
    { code: "17", description: "Pre-Admission Testing" },
    { code: "18", description: "Durable Medical Equipment Rental" },
    { code: "19", description: "Pneumonia Vaccine" },
    { code: "20", description: "Second Surgical Opinion" },
    { code: "21", description: "Third Surgical Opinion" },
    { code: "22", description: "Social Work" },
    { code: "23", description: "Diagnostic Dental" },
    { code: "24", description: "Periodontics" },
    { code: "25", description: "Restorative" },
    { code: "26", description: "Endodontics" },
    { code: "27", description: "Maxillofacial Prosthetics" },
    { code: "28", description: "Adjunctive Dental Services" },
    { code: "30", description: "Plan Coverage and General Benefits" },
    { code: "32", description: "Plan Waiting Period" },
    { code: "33", description: "Chiropractic" },
    { code: "35", description: "Dental Care" },
    { code: "36", description: "Dental Crowns" },
    { code: "37", description: "Dental Accident" },
    { code: "38", description: "Orthodontics" },
    { code: "39", description: "Prosthodontics" },
    { code: "40", description: "Oral Surgery" },
    { code: "41", description: "Preventive Dental" },
    { code: "42", description: "Home Health Care" },
    { code: "43", description: "Home Health Prescriptions" },
    { code: "45", description: "Hospice" },
    { code: "46", description: "Respite Care" },
    { code: "47", description: "Hospitalization" },
    { code: "49", description: "Hospital - Room and Board" },
    { code: "54", description: "Long Term Care" },
    { code: "55", description: "Major Medical" },
    { code: "56", description: "Medically Related Transportation" },
    { code: "60", description: "Plan Coverage" },
    { code: "61", description: "In-vitro Fertilization" },
    { code: "62", description: "MRI Scan" },
    { code: "63", description: "Donor Procedures" },
    { code: "64", description: "Acupuncture" },
    { code: "65", description: "Newborn Care" },
    { code: "66", description: "Pathology" },
    { code: "67", description: "Smoking Cessation" },
    { code: "68", description: "Well Baby Care" },
    { code: "69", description: "Maternity" },
    { code: "70", description: "Transplants" },
    { code: "71", description: "Audiology" },
    { code: "72", description: "Inhalation Therapy" },
    { code: "73", description: "Diagnostic Medical" },
    { code: "74", description: "Private Duty Nursing" },
    { code: "75", description: "Prosthetics" },
    { code: "76", description: "Dialysis" },
    { code: "77", description: "Otology" },
    { code: "78", description: "Chemotherapy" },
    { code: "79", description: "Allergy Testing" },
    { code: "80", description: "Immunizations" },
    { code: "81", description: "Routine Physical" },
    { code: "82", description: "Family Planning" },
    { code: "83", description: "Infertility" },
    { code: "84", description: "Abortion" },
    { code: "85", description: "HIV - AIDS Treatment" },
    { code: "86", description: "Emergency Services" },
    { code: "87", description: "Cancer Treatment" },
    { code: "88", description: "Retail/Independent Pharmacy" },
    { code: "89", description: "Free Standing Prescription Drug" },
    { code: "90", description: "Mail Order Pharmacy" },
    { code: "91", description: "Brand Name Prescription Drug" },
    { code: "92", description: "Generic Prescription Drug" },
    { code: "93", description: "Podiatry" },
    { code: "94", description: "Dental and Prediagnostic Tests and Examinations" },
    { code: "95", description: "Periodontal Surgical Services" },
    { code: "96", description: "Adjustment to Dentures/Repairs to Complete Dentures, Denture Rebase Procedures, and Denture Reline Procedures" },
    { code: "97", description: "Dental, Non-Surgical Extractions" },
    { code: "98", description: "Prescription Drug" },
    { code: "99", description: "Bariatric Services" },
    { code: "A4", description: "Psychiatric" },
    { code: "A6", description: "Psychotherapy" },
    { code: "A7", description: "Psychiatric - Inpatient" },
    { code: "A8", description: "Psychiatric - Outpatient" },
    { code: "A9", description: "Rehabilitation" },
    { code: "AB", description: "Rehabilitation - Inpatient" },
    { code: "AC", description: "Rehabilitation - Outpatient" },
    { code: "AD", description: "Occupational Therapy" },
    { code: "AE", description: "Physical Medicine" },
    { code: "AF", description: "Speech Therapy" },
    { code: "AG", description: "Skilled Nursing Care" },
    { code: "AI", description: "Substance Abuse" },
    { code: "AJ", description: "Alcoholism Treatment" },
    { code: "AK", description: "Drug Addiction" },
    { code: "AL", description: "Optometry" },
    { code: "AM", description: "Frames" },
    { code: "AO", description: "Lenses" },
    { code: "AP", description: "Routine Eye Exam" },
    { code: "AQ", description: "Nonmedically Necessary Physical" },
    { code: "AR", description: "Experimental Drug Therapy" },
    { code: "B1", description: "Burn Care" },
    { code: "B2", description: "Brand Name Prescription Drug - Formulary" },
    { code: "B3", description: "Brand Name Prescription Drug - Non-Formulary" },
    { code: "BA", description: "Independent Medical Evaluation" },
    { code: "BB", description: "Psychiatric Treatment Partial Hospitalization" },
    { code: "BC", description: "Day Care (Psychiatric)" },
    { code: "BD", description: "Cognitive Therapy" },
    { code: "BE", description: "Massage Therapy" },
    { code: "BF", description: "Pulmonary Rehabilitation" },
    { code: "BG", description: "Cardiac Rehabilitation" },
    { code: "BH", description: "Pediatric" },
    { code: "BI", description: "Nursery Room and Board" },
    { code: "BK", description: "Orthopedic" },
    { code: "BL", description: "Cardiac" },
    { code: "BM", description: "Lymphatic" },
    { code: "BN", description: "Gastrointestinal" },
    { code: "BP", description: "Endocrine" },
    { code: "BQ", description: "Neurology" },
    { code: "BT", description: "Gynecological" },
    { code: "BU", description: "Obstetrical" },
    { code: "BV", description: "Obstetrical/Gynecological" },
    { code: "BY", description: "Physician Visit - Sick" },
    { code: "BZ", description: "Physician Visit - Well" },
    { code: "C1", description: "Coronary Care" },
    { code: "CK", description: "Screening X-ray" },
    { code: "CL", description: "Screening laboratory" },
    { code: "CM", description: "Mammogram, High Risk Patient" },
    { code: "CN", description: "Mammogram, Low Risk Patient" },
    { code: "CO", description: "Flu Vaccination" },
    { code: "CP", description: "Eyewear Accessories" },
    { code: "CQ", description: "Case Management" },
    { code: "DG", description: "Dermatology" },
    { code: "DM", description: "Durable Medical Equipment" },
    { code: "DS", description: "Diabetic Supplies" },
    { code: "E0", description: "Applied Behavioral Analysis Therapy" },
    { code: "E1", description: "Non-Medical Equipment (non DME)" },
    { code: "E2", description: "Psychiatric Emergency" },
    { code: "E3", description: "Step Down Unit" },
    { code: "E4", description: "Skilled Nursing Facility Head Level of Care" },
    { code: "E5", description: "Skilled Nursing Facility Ventilator Level of Care" },
    { code: "E6", description: "Level of Care 1" },
    { code: "E7", description: "Level of Care 2" },
    { code: "E8", description: "Level of Care 3" },
    { code: "E9", description: "Level of Care 4" },
    { code: "E10", description: "Radiographs" },
    { code: "E11", description: "Diagnostic Imaging" },
    { code: "E14", description: "Fixed Prosthodontics" },
    { code: "E15", description: "Removable Prosthodontics" },
    { code: "E16", description: "Intraoral Images - Complete Series" },
    { code: "E17", description: "Oral Evaluation" },
    { code: "E18", description: "Dental Prophylaxis" },
    { code: "E19", description: "Panoramic Images" },
    { code: "E20", description: "Sealants" },
    { code: "E21", description: "Fluoride Treatments" },
    { code: "E22", description: "Dental Implants" },
    { code: "E23", description: "Temporomandibular Joint Dysfunction" },
    { code: "E25", description: "Long Term Care Pharmacy" },
    { code: "E26", description: "Comprehensive Medication Therapy Management Review" },
    { code: "E27", description: "Targeted Medication Therapy Management Review" },
    { code: "E28", description: "Dietary/Nutritional Services" },
    { code: "E33", description: "Intensive Cardiac Rehabilitation" },
    { code: "E36", description: "Convenience Care" },
    { code: "E37", description: "Telemedicine" },
    { code: "E38", description: "Pharmacist Services" },
    { code: "E39", description: "Diabetic Education" },
    { code: "E40", description: "Early Intervention" },
    { code: "EA", description: "Preventive Services" },
    { code: "EB", description: "Specialty Pharmacy" },
    { code: "EC", description: "Durable Medical Equipment New" },
    { code: "ED", description: "CAT Scan" },
    { code: "EE", description: "Ophthalmology" },
    { code: "EF", description: "Contact Lenses" },
    { code: "EG", description: "Fertility Preservation" },
    { code: "EH", description: "Medically Tailored Meals (MTM)" },
    { code: "EJ", description: "IV Therapy" },
    { code: "F1", description: "Medical Coverage" },
    { code: "F2", description: "Social Work Coverage" },
    { code: "F3", description: "Dental Coverage" },
    { code: "F4", description: "Hearing Coverage" },
    { code: "F5", description: "Prescription Drug Coverage" },
    { code: "F6", description: "Vision Coverage" },
    { code: "F7", description: "Orthodontia Coverage" },
    { code: "F8", description: "Mental Health Coverage" },
    { code: "GF", description: "Generic Prescription Drug - Formulary" },
    { code: "GN", description: "Generic Prescription Drug - Non-Formulary" },
    { code: "GY", description: "Allergy" },
    { code: "IC", description: "Intensive Care" },
    { code: "MH", description: "Mental Health" },
    { code: "NI", description: "Neonatal Intensive Care" },
    { code: "ON", description: "Oncology" },
    { code: "PE", description: "Positron Emission Tomography (PET) Scan" },
    { code: "PT", description: "Physical Therapy" },
    { code: "PU", description: "Pulmonary" },
    { code: "RN", description: "Renal" },
    { code: "RT", description: "Residential Psychiatric Treatment" },
    { code: "SMH", description: "Serious Mental Health" },
    { code: "TC", description: "Transitional Care" },
    { code: "TN", description: "Transitional Nursery Care" },
    { code: "UC", description: "Urgent Care" },
    ];

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredServiceTypeCodes, setFilteredServiceTypeCodes] = useState(initialServiceTypeCodes);
    const [selectedServiceType, setSelectedServiceType] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  
    const handleSearchInputChange = (event) => {
      const { value } = event.target;
      setSearchTerm(value);
  
      const filteredCodes = initialServiceTypeCodes.filter(({ code, description }) =>
        code.toLowerCase().includes(value.toLowerCase()) ||
        description.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredServiceTypeCodes(filteredCodes);
    };
  
    const handleOptionClick = (code, description) => {
      setSelectedServiceType(`${description}`);
      onServiceTypeChange(code);
      setIsDropdownVisible(false);
    };
  
    // Inside the return statement of your component
return (
    <div className={styles.serviceTypeDropdown}>
      <input 
        type="text" 
        className="dropdownInput"
        placeholder="Select Service Type" 
        value={selectedServiceType}
        readOnly
        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
      />
      {isDropdownVisible && (
        <div className={styles.dropdownContent}>
          <input 
            type="text" 
            className="searchInput"
            placeholder="Search Service Types..." 
            value={searchTerm} 
            onChange={handleSearchInputChange}
          />
          <div className="optionsContainer">
            {filteredServiceTypeCodes.map(({ code, description }) => (
              <div key={code} className="optionItem" onClick={() => handleOptionClick(code, description)}>
                {`${description}`}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
  
  }
  
  export default ServiceTypeDropdown;