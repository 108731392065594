import React from "react";

const InputTypeSpacer = ({ fieldItem, isVisible = true, preview = true }) => {
  return (
    <>
      {isVisible && (
        <>
          {preview ? (
            // Preview Mode
            <div
              style={{
                height: `${fieldItem?.default}px`,
                border: "1px dashed #ccc",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <span>{fieldItem?.default}px</span>
            </div>
          ) : (
            // Edit Mode (or Non-Preview Mode)
            <div
              style={{
                height: `${fieldItem?.default}px`,
              }}
            >
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InputTypeSpacer;
