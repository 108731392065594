import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';
import styles from '../styles/accordion.module.css';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef(
  ({ ...props }, ref) => (
    <AccordionPrimitive.Item
      ref={ref}
      className={styles.item}
      {...props}
    />
  )
);
AccordionItem.displayName = 'AccordionItem';


const AccordionTrigger = React.forwardRef(
  ({ children, ...props }, ref) => (
    <AccordionPrimitive.Header>
      <AccordionPrimitive.Trigger
        ref={ref}
        className={styles.trigger}
        {...props}
      >
        {children}
        <ChevronDown className="transition-transform duration-200" />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef(
  ({ children, ...props }, ref) => (
    <AccordionPrimitive.Content
      ref={ref}
      className={styles.content}
      {...props}
    >
      <div>{children}</div>
    </AccordionPrimitive.Content>
  )
);
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
