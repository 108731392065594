import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { storage, auth, db } from '../../firebase';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import uploadFile from './upload';
import { getFirestore, collection, getDocs, doc, updateDoc, onSnapshot, query, where } from 'firebase/firestore';
import './styles/Home.module.css'

function Documents({ onDocumentSelect }) {
  const [sources, setSources] = useState([]);
  const [error, setError] = useState(null);

  const onDrop = (acceptedFiles) => {
    handleUpload(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const getDocuments = () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      setError('No user signed in');
      return;
    }
  
    const db = getFirestore();
    const docRef = collection(db, 'documents');
  
    const unsubscribe = onSnapshot(query(docRef, where('uid', '==', currentUser.uid), where('deleteFlag', '==', 1)), 
      (snapshot) => {
        const documents = snapshot.docs.map(doc => ({
          originalFilename: doc.data().originalFilename,
          deleteFlag: doc.data().deleteFlag,
          id: doc.id,
          uid: doc.data().uid,
          status: doc.data().status, 
        }));
        setSources(documents);
      },
      (error) => {
        console.error('Error fetching documents:', error);
        setError('Failed to fetch documents');
      }
    );
  
    // Clean up subscription on unmount
    return unsubscribe;
  };
  
  // In your useEffect hook:
  useEffect(() => {
    const unsubscribe = getDocuments();
  
    // Returning a function in useEffect works like a "componentWillUnmount" lifecycle method
    // This is where you can cancel your snapshot listener when the component is unmounting.
    return () => unsubscribe();
  }, []);
  
  

  useEffect(() => {
    getDocuments();
  }, []);

  const handleDelete = async (document) => {
    try {
      const db = getFirestore();
      const docRef = doc(db, 'documents', document.id);
      await updateDoc(docRef, { deleteFlag: 0 });
      getDocuments();
    } catch (error) {
      console.error('Error deleting document:', error);
      setError('Failed to delete document');
    }
  };

  const handleUpload = async (files) => {
    if (!files.length) {
      setError('No file selected');
      return;
    }

    const currentUser = auth.currentUser;
    if (!currentUser) {
      setError('No user signed in');
      return;
    }

    try {
      const results = await Promise.all(files.map((file) => uploadFile(file, currentUser)));
      
      // If any of the uploads failed, show an error message.
      const failedUpload = results.find(result => !result.success);
      if (failedUpload) {
        setError(failedUpload.message);
      } else {
        setError(null);
        getDocuments();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className="sidebar">
      <div className='uploads-list'>
      {sources.map((document, index) => (
        <div className="documentContainer" key={index}>
          <FontAwesomeIcon icon={faFileAlt} className="svg-inline--fa" />
            <div className='documentLabel'>
              <p className="textLabel">{document.originalFilename}</p>
              {document.status === 'processing' ? (
                  <div><p className="processingLabel">Processing</p>
                  <button className='buttonai' onClick={() => handleDelete(document)}>
                  Delete
                </button>
                </div>
                ) : document.status.startsWith('error') ? (
                  <div><p className="errorLabel">{document.status}</p>
                  <button className='buttonai' onClick={() => handleDelete(document)}>
                    Delete
                  </button></div>
                ) : (
                  document.deleteFlag !== 0 && 
                  <button className='buttonai' onClick={() => handleDelete(document)}>
                    Delete
                  </button>
                )}

            </div>
          </div>
      ))}

    </div>

      <hr className="mb-4" />

      <p className="text-center">Document Upload (PDFs):</p>
      {error && (
        <div className="mb-5 text-center">
          <p className="text-red-600">{error}</p>
        </div>
      )}

<div {...getRootProps()} className="dropbox">
  <input {...getInputProps()} />
  {isDragActive ? (
    <p style={{ textAlign: 'center' }}>Drop the files here...</p>
  ) : (
    <p style={{ textAlign: 'center' }}>Click or drop a file here to upload</p>
  )}
</div>
    </div>
  );
}
export default Documents;
