import React, { useState, useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { auth, db, storage } from "../../firebase";
import { collection, addDoc, updateDoc, doc, getDocs } from "firebase/firestore";
import ProviderSelection from '../Providers/ProviderSelection';
import PayerSelection from '../Payers/PayerSelection';
import '../PatientFiles/AddPatient/AddPatient.modules.css';
import InputMask from 'react-input-mask';
import { getFunctions, httpsCallable } from "firebase/functions";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import HexagonSpinner from "../General/Animations/Hexspinner";
import ProviderSearch from "./ProviderSearch";
import LibreLogo from "./libre_logo.jpg";
import DexcomLogo from "./dexcom_logo.png";

const TerraSignIn = () => {
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganizationUid, setSelectedOrganizationUid] = useState(null);
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState("");
    const [selectedPayer, setSelectedPayer] = useState(null);
    const [selectedServiceProvider, setSelectedServiceProviders] = useState(null);
    const [selectedBillingProvider, setSelectedBillingProviders] = useState(null);
    const [payers] = useState([]);
    const [memberId, setMemberId] = useState("");
    const [address, setAddress] = useState({
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
    });
    const [newPatient, setNewPatient] = useState({
        firstName: "",
        middleName: "", 
        lastName: "",
        dob: "",
        gender: "",
    });
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [file, setFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [signature, setSignature] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);


  const devices = [
    { name: 'Libre', label: 'Freestyle Libre', logo: LibreLogo },
    { name: 'Dexcom', label: 'Dexcom', logo: DexcomLogo },
  ];

  const handleDeviceSelection = (deviceName) => {
    setSelectedDevice(deviceName);
  };

    const sigPad = useRef();

    useEffect(() => {
        const fetchOrganizations = async () => {
            const organizationsRef = collection(db, "users");
            const organizationDocs = await getDocs(organizationsRef);
            const filteredOrganizations = organizationDocs.docs
                .map(doc => ({ ...doc.data(), uid: doc.id }))
                .filter(org => org.OrganizationName !== "CONVENIENT MONITORING GROUP LLC");
    
            setOrganizations(filteredOrganizations);
        };
    
        fetchOrganizations();
    }, []);
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPatient((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const formatDate = (date) => {
        const [year, month, day] = date.split('-');
        return `${month}/${day}/${year}`;
    };

    const handleAddPatient = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Validate mandatory fields
        if (!selectedOrganizationUid) {
            alert("Please select an organization first.");
            setLoading(false);
            return;
        }

        if (!newPatient.firstName) {
            alert("Please enter the first name.");
            setLoading(false);
            return;
        }

        if (!newPatient.lastName) {
            alert("Please enter the last name.");
            setLoading(false);
            return;
        }

        if (!dob) {
            alert("Please enter the date of birth.");
            setLoading(false);
            return;
        }

        if (!gender) {
            alert("Please select the gender.");
            setLoading(false);
            return;
        }

        if (!address.address1) {
            alert("Please enter the address line 1.");
            setLoading(false);
            return;
        }

        if (!address.city) {
            alert("Please enter the city.");
            setLoading(false);
            return;
        }

        if (!address.state) {
            alert("Please enter the state.");
            setLoading(false);
            return;
        }

        if (!address.zip) {
            alert("Please enter the zip code.");
            setLoading(false);
            return;
        }

        if (!email) {
            alert("Please enter the email.");
            setLoading(false);
            return;
        }

        if (!phone) {
            alert("Please enter the phone number.");
            setLoading(false);
            return;
        }

        if (!memberId) {
            alert("Please enter the member ID.");
            setLoading(false);
            return;
        }

        if (!selectedPayer) {
            alert("Please select a payer.");
            setLoading(false);
            return;
        }

        if (!file) {
            alert("Please upload a picture of the insurance ID card.");
            setLoading(false);
            return;
        }

        if (sigPad.current.isEmpty()) {
            alert("Please provide a signature.");
            setLoading(false);
            return;
        }

        const uid = selectedOrganizationUid;
        const patientsRef = collection(db, "patients", uid, "patientData");
        const formattedDob = formatDate(dob);

        const newPatientData = {
            provider: selectedProvider,
            patient: {
                ...newPatient,
                dob: formattedDob,
                gender,
                address,
                email,
                phone,
            },
        };

        const patientDoc = await addDoc(patientsRef, newPatientData);
        const patientId = patientDoc.id;

        // Save insurance card file
        const filePath = `PatientDocuments/${uid}/${file.name}`;
        const fileRef = ref(storage, filePath);

        try {
            await uploadBytes(fileRef, file);
            const downloadUrl = await getDownloadURL(fileRef);
            const filesRef = collection(db, "patients", uid, "patientData", patientId, "files");
            await addDoc(filesRef, { name: file.name, url: downloadUrl, timestamp: new Date() });
        } catch (error) {
            console.error("Error uploading the file:", error);
        }

        // Save signature
        const signatureDataUrl = sigPad.current.toDataURL();
        const signatureBlob = await (await fetch(signatureDataUrl)).blob();
        const signatureFilePath = `PatientDocuments/${uid}/${patientId}_signature.png`;
        const signatureFileRef = ref(storage, signatureFilePath);

        try {
            await uploadBytes(signatureFileRef, signatureBlob);
            const signatureDownloadUrl = await getDownloadURL(signatureFileRef);
            await updateDoc(doc(db, "patients", uid, "patientData", patientId), { signature: signatureDownloadUrl });
        } catch (error) {
            console.error("Error uploading the signature:", error);
        }

        const referenceId = `${uid}-${patientId}`;

        if (selectedPayer) {
            const payerWithMemberId = { ...selectedPayer, memberId };
            await updateDoc(doc(db, "patients", uid, "patientData", patientId), { payers: payerWithMemberId });
        }

        if (selectedServiceProvider) {
            await updateDoc(doc(db, "patients", uid, "patientData", patientId), { serviceProviders: selectedServiceProvider });
        }

        if (selectedBillingProvider) {
            await updateDoc(doc(db, "patients", uid, "patientData", patientId), { billingProviders: selectedBillingProvider });
        }

        try {
            const functions = getFunctions();
            const terraAuthFunction = httpsCallable(functions, "terraAuth");
        
            // Send both referenceId and selected device type as `resource`
            const response = await terraAuthFunction({
                reference_id: referenceId,
                resource: selectedDevice === 'Dexcom' ? 'DEXCOM' : 'FREESTYLELIBRE'
            });
        
            const authUrl = response.data.auth_url;
            setLoading(false);
            window.location.href = authUrl;  // Redirect to Terra authorization page
        } catch (error) {
            console.error("Error during authorization:", error);
            setLoading(false);
        }
        
        
    // Clear form fields after submission
    setNewPatient({
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
    });
};

    const handleFileChange = (file) => {
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onCameraClick = async () => {
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            const videoElement = document.getElementById('videoPreview');
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
                videoElement.srcObject = stream;
                videoElement.play();
                document.getElementById('captureButton').style.display = 'block';
            } catch (error) {
                alert("Couldn't access the camera. Please enable camera permissions in the browser settings.");
            }
        } else {
            document.getElementById("fileInput").click();
        }
    };

    const capturePhoto = () => {
        const videoElement = document.getElementById('videoPreview');
        const canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => {
            const capturedFile = new File([blob], "InsuranceCardfromIntake.jpeg", { type: "image/jpeg" });
            setFile(capturedFile);
            const dataURL = URL.createObjectURL(blob);
            setPreviewImage(dataURL);
        }, 'image/jpeg');
        videoElement.srcObject.getTracks().forEach(track => track.stop());
    };


    return (
        <div className="add-patient-form">
         <div className="content-center" style={{ marginTop: "3rem" }}>
                <h3>CGM Device Registration Form</h3>

                <div className="device-selection">
                    <label>Select your CGM Device' Manufacture</label>
                    <div className="device-options">
                        {devices.map((device) => (
                        <label
                            key={device.name}
                            className={`device-option ${
                            selectedDevice === device.name ? "selected" : ""
                            }`}
                        >
                            <input
                            type="radio"
                            name="device"
                            value={device.name}
                            checked={selectedDevice === device.name}
                            onChange={() => handleDeviceSelection(device.name)}
                            />
                            <img src={device.logo} alt={device.label} className="device-logo" />
                            <span>{device.label}</span>
                        </label>
                        ))}
                    </div>

                    {/* Instructions based on selected device */}
                    {selectedDevice === "Libre" ? (
                        <>
                        <p>1. Create an account with Freestyle Libre if you don't have one.</p>
                        <button
                            onClick={() =>
                            window.open(
                                "https://www.freestyle.abbott/ie-en/myfreestyle/login.html",
                                "_blank"
                            )
                            }
                        >
                            If you don't have a Libre account, please click here.
                        </button>
                        <p>2. Fill out the form below in its entirety.</p>
                        <p>3. Log in to your Libre account when prompted.</p>
                        </>
                    ) : selectedDevice === "Dexcom" ? (
                        <>
                        <p>1. Create an account with Dexcom if you don't have one.</p>
                        <button
                            onClick={() =>
                            window.open("https://provider.dexcom.com", "_blank")
                            }
                        >
                            If you don't have a Dexcom account, please click here.
                        </button>
                        <p>2. Fill out the form below in its entirety.</p>
                        <p>3. Log in to your Dexcom account when prompted.</p>
                        </>
                    ) : (
                        <p>Please select a device to continue.</p>
                    )}
                    </div>
                    </div>

            <div className="input-group">
                <label htmlFor="organization">Organization</label>
                <select
                    name="organization"
                    onChange={(e) => setSelectedOrganizationUid(e.target.value)}
                    required
                    className="dropdownProviderSelect"
                >
                    <option value="">--Please choose an organization--</option>
                    {organizations.map(org => (
                        <option key={org.uid} value={org.uid}>{org.OrganizationName}</option>
                    ))}
                </select>
            </div>

            <div className="input-group-row">
                <div className="input-field">
                    <label htmlFor="firstName">First Name</label>
                    <input
                        type="text"
                        name="firstName"
                        value={newPatient.firstName}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="input-field">
                    <label htmlFor="middleName">Middle Name (optional)</label>
                    <input
                        type="text"
                        name="middleName"
                        value={newPatient.middleName}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-field">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        type="text"
                        name="lastName"
                        value={newPatient.lastName}
                        onChange={handleInputChange}
                        required
                    />
                </div>
            </div>

            <div className="input-group">
                <label htmlFor="dob">Date of Birth</label>
                <input
                    type="date"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    required
                />
                <div className="gender-buttons">
                    <button
                        type="button"
                        className={`gender-button ${gender === "M" ? "selected" : ""}`}
                        onClick={() => setGender("M")}
                        required
                    >
                        M
                    </button>
                    <button
                        type="button"
                        className={`gender-button ${gender === "F" ? "selected" : ""}`}
                        onClick={() => setGender("F")}
                        required
                    >
                        F
                    </button>
                </div>
            </div>

            <div className="input-group-row">
                <div className="input-field">
                    <label htmlFor="address1">Address line 1</label>
                    <input
                        type="text"
                        name="address1"
                        value={address.address1}
                        onChange={(e) => setAddress({ ...address, address1: e.target.value })}
                        required
                    />
                </div>
                <div className="input-field">
                    <label htmlFor="address2">Address line 2 (optional)</label>
                    <input
                        type="text"
                        name="address2"
                        value={address.address2}
                        onChange={(e) => setAddress({ ...address, address2: e.target.value })}
                    />
                </div>
            </div>

            <div className="input-group-row">
                <div className="input-field">
                    <label htmlFor="city">City</label>
                    <input
                        type="text"
                        name="city"
                        value={address.city}
                        onChange={(e) => setAddress({ ...address, city: e.target.value })}
                        required
                    />
                </div>
                <div className="input-field">
                    <label htmlFor="state">State</label>
                    <input
                        type="text"
                        name="state"
                        maxLength="2"
                        pattern="^[A-Z]{2}$"
                        value={address.state}
                        onChange={(e) => setAddress({ ...address, state: e.target.value })}
                        className="stateInput"
                        required
                    />
                </div>
                <div className="input-field">
                    <label htmlFor="zip">Zip</label>
                    <input
                        type="text"
                        name="zip"
                        maxLength="5"
                        pattern="^\d{5}$"
                        value={address.zip}
                        onChange={(e) => setAddress({ ...address, zip: e.target.value })}
                        className="zipInput"
                        required
                    />
                </div>
            </div>

            <div className="input-group">
                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="input-group">
                <label htmlFor="phone">Phone</label>
                <InputMask
                    mask="(999) 999-9999"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="(000)-000-0000"
                    required
                >
                    {(inputProps) => <input {...inputProps} type="text" name="phone" />}
                </InputMask>
            </div>

            <div className="input-group">
                <label htmlFor="memberId">Member ID</label>
                <input
                    type="text"
                    name="memberId"
                    value={memberId}
                    onChange={(e) => setMemberId(e.target.value)}
                    required
                />
            </div>

            <div className="input-group-payerSelection">
                <PayerSelection
                    payers={payers}
                    onSelect={setSelectedPayer}
                    selectedOrganizationUid={selectedOrganizationUid} // Pass the selected organization UID
                    required
                    mode={'claimStatus'}
                    className="payerContainer"
                />
            </div>


            <h3>Please add you Primary Care Provider</h3>
             {/* Provider Search Component */}
            <ProviderSearch onProviderSelect={setSelectedProvider} />


            <div className="input-group">
                {(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) ? (
                    <>
                        {previewImage ? (
                            <div className="preview-image-container">
                                <img src={previewImage} id="previewImage" alt="Preview" />
                                <button onClick={() => setPreviewImage(null)}>
                                    <FontAwesomeIcon icon={faCamera} />
                                    Retake Photo of Insurance ID Card
                                </button>
                            </div>
                        ) : (
                            <>
                                <video id="videoPreview" width="320" height="240" autoPlay muted playsInline style={{ display: 'none' }}></video>
                                <button id="startCameraButton" onClick={onCameraClick}>
                                    <FontAwesomeIcon icon={faCamera} />
                                    Take picture of Insurance ID Card
                                </button>
                                <button id="captureButton" onClick={capturePhoto} style={{ display: 'none' }}>
                                    Capture
                                </button>
                            </>
                        )}
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            accept="image/*"
                            capture="camera"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    handleFileChange(e.target.files[0]);
                                }
                            }}
                            required
                        />
                    </>
                ) : (
                    <>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            accept="image/*"
                            capture="camera"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    handleFileChange(e.target.files[0]);
                                }
                            }}
                            required
                        />
                        <button onClick={onCameraClick} className="cameraButton">
                            <FontAwesomeIcon icon={faCamera} />
                            {file ? ' Change Photo' : ' Take/Select Photo of Insurance Card'}
                        </button>
                    </>
                )}
            </div>

            <div className="legal-disclaimer">
            <p className="sign-up-legal-disclaimer">
                <strong>Summary:</strong> By signing below, you agree to participate in Transglobal Care's remote patient monitoring and chronic care management programs. This includes the release of your health records for monitoring, usage of provided medical devices, and sharing your health data with involved healthcare providers. You can withdraw your consent at any time.

                <br/><br/>

                <strong>REMOTE PATIENT MONITORING AGREEMENT</strong><br/>
                You authorize Transglobal Care to access and monitor your health records using remote patient monitoring services. Your data will be used to monitor your health, provide timely alerts, and communicate with you about your health status. Your records will be kept confidential.

                <br/><br/>

                <strong>CONSENT TO RELEASE HEALTH RECORDS</strong><br/>
                By signing this form, you consent to release your health records to Transglobal Care for the purposes of remote patient monitoring and chronic care management. Your records will be used confidentially for these purposes.

                <br/><br/>

                <strong>REMOTE PATIENT MONITORING SERVICES</strong><br/>
                Purpose: Monitor your health, identify changes, and provide insights.<br/>
                Information Collected: Personal and health information, including medical history and current health status.<br/>
                Usage: Monitor health, provide alerts, communicate status, and improve services.<br/>
                Disclosure: Information may be shared with third-party service providers and government agencies as required by law.<br/>
                Rights: You can access, review, correct, restrict use, or revoke consent for your information at any time.<br/>
                Charges: No charges without prior consent. If insurance does not cover 100% of the service, you may be liable for a copay. CMG will notify you before any charges.

                <br/><br/>

                <strong>CHRONIC CARE MANAGEMENT AGREEMENT</strong><br/>
                By signing, you agree to participate in the Chronic Care Management program. This program helps manage chronic conditions with a comprehensive care plan and dedicated care team. Your insurance may be billed for these services, and you can only have one physician managing these services at a time. You can withdraw from the program at any time.

                <br/><br/>

                <strong>CONTINUOUS GLUCOSE MONITORING (CGM) CONSENT FORM</strong><br/>
                Purpose: Continuous tracking of glucose levels for diabetes management.<br/>
                Description: Small sensor under the skin monitors glucose levels, transmitting data to a device.<br/>
                Risks and Benefits: Informed about potential skin irritation, inaccurate readings, and device failure. Benefits include better diabetes management and awareness.<br/>
                Responsibilities: Follow instructions for sensor usage, monitor readings, and report concerns.<br/>
                Data Sharing: Consent to share glucose data with healthcare providers.<br/>
                Duration and Withdrawal: Voluntary participation, can withdraw at any time.<br/>
                Follow-up: Attend recommended follow-up appointments for data review and treatment adjustments.<br/>
                Consent: By signing, you agree to participate in the CGM program.

                <br/><br/>

                By signing below, you acknowledge that you have read and understand this consent form and agree to participate in Transglobal Care's programs.
            </p>

            </div>

            <div className="signature-pad">
                <div style={{ padding: ".5rem" }}>
                    <h3>Please sign if you consent to the above:</h3>
                </div>
                <SignatureCanvas
                    ref={sigPad}
                    penColor="black"
                    canvasProps={{ className: 'sigCanvas' }}
                />
                <button onClick={() => sigPad.current.clear()}>
                    Clear
                </button>
                </div>



            {loading ? (<HexagonSpinner /> ) : (
                <div className="addPatientButton">
                    <button onClick={handleAddPatient} disabled={loading}>
                        Add Patient
                    </button>
                </div>
            )}
        </div>
    );
};

export default TerraSignIn;
