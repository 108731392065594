import { useState, useEffect, useMemo, useRef } from "react";
import { doc, deleteDoc, setDoc, getDoc, collection, getDocs } from "firebase/firestore";
import { unparse } from 'papaparse';
import './PatientView.module.css';
import { auth, db } from "../../firebase";
import { getFunctions, httpsCallable } from 'firebase/functions';
import styles from "./PatientView.module.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import useUID from '../General/useUID'
import mapPatientDataForDownload from './DownloadPatientFileMapping.js';
import Modal from 'react-modal';
import * as XLSX from 'xlsx';

const functions = getFunctions();

const capitalizeName = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};


const PatientDataDownload = ({ patientSelected }) => {
    const [uid, subUserUID] = useUID();
    const [isSubcollectionModalOpen, setIsSubcollectionModalOpen] = useState(false);
    const [isDownloadFormatModalOpen, setIsDownloadFormatModalOpen] = useState(false);
    const [selectedSubcollections, setSelectedSubcollections] = useState({
        encounters: true,
        notes: true,
        eligibility: true,
        claims: true,
        claimStatus: true,
        medicalHistory: true
    });

    const handleCheckboxChange = (event) => {
        setSelectedSubcollections({
            ...selectedSubcollections,
            [event.target.name]: event.target.checked
        });
    };

    const handleNextClick = () => {
        setIsSubcollectionModalOpen(false);
        setIsDownloadFormatModalOpen(true);
    };

    const handleBackClick = () => {
        setIsDownloadFormatModalOpen(false);
        setIsSubcollectionModalOpen(true);
    };

    const handleDownload = async (type) => {
        setIsDownloadFormatModalOpen(false);
        try {
            const patientRef = doc(db, "patients", uid, "patientData", patientSelected.id);
            const patientDoc = await getDoc(patientRef);
    
            if (!patientDoc.exists()) {
                console.error("No patient found");
                return;
            }
    
            let patientData = patientDoc.data() || {};
            patientData.encounters = await fetchCollection(patientRef, "encounters");
            patientData.notes = await fetchCollection(patientRef, "notes");
            patientData.eligibility = await fetchCollection(patientRef, "eligibility");
            patientData.claims = await fetchCollection(patientRef, "claims");
            patientData.claimStatus = await fetchCollection(patientRef, "claimStatus");
            patientData.medicalHistory = await fetchCollection(patientRef, "medicalHistory");
    
              // Flatten the data using the mapping function
            const flattenedData = mapPatientDataForDownload(patientData);
            console.log(flattenedData)
            const userData = await fetchUserData(uid);

            const patientBasicInfo = {
                fullName: `${capitalizeName(flattenedData.firstName)} ${capitalizeName(flattenedData.middleName)} ${capitalizeName(flattenedData.lastName)}`,
                firstName: `${capitalizeName(flattenedData.firstName)}`,
                middleName: `${capitalizeName(flattenedData.middleName)}`,
                lastName: `${capitalizeName(flattenedData.lastName)}`,
                memberId: flattenedData.memberId || '',
                insurance: flattenedData.insurance || '',
                
                // Secondary Payer Information
                secondaryInsurance: flattenedData.secondaryInsurance || '',
                secondaryMemberId: flattenedData.secondaryMemberId || '',
                
                dob: flattenedData.dob || '',
                phone: flattenedData.phone || '',
                email: flattenedData.email || '',
                address1: `${flattenedData.address1 || ''}`,
                address2: `${flattenedData.address2 || ''}`,
                city: `${flattenedData.city || ''}`,
                state: `${flattenedData.state || ''}`,
                zip: `${flattenedData.zip || ''}`,
                flag: flattenedData.flag || ''
            };
            

            if (type === 'xlsx') {
                handleDownloadExcel(flattenedData, patientBasicInfo, 'PatientData.xlsx');
            } else if (type === 'pdf') {
                generatePDF(flattenedData, patientBasicInfo, `Patient_${patientSelected.id}_Data.pdf`, userData);
            }
            

            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            const ipAddress = data.ip;

            const addLogFunction = httpsCallable(functions, 'addLog');
            await addLogFunction({
                uid,
                message: `Individual Patient data downloaded. User IP address: ${ipAddress}.`
            });
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const fetchCollection = async (patientRef, collectionName) => {
        const collectionRef = collection(patientRef, collectionName);
        const snapshot = await getDocs(collectionRef);
        return snapshot.docs.map(doc => doc.data()).filter(item => !item.isDeleted);
    };

    const handleDownloadExcel = (flattenedData, patientInfo, filename) => {
        const workbook = XLSX.utils.book_new();
    
        // Create a sheet for patient's basic information
        const patientInfoSheet = XLSX.utils.json_to_sheet([patientInfo]); // Wrap in array to create one row
        XLSX.utils.book_append_sheet(workbook, patientInfoSheet, 'Patient_Info');
    
        // Rest of the code to add sheets for each subcollection
        Object.entries(flattenedData).forEach(([key, value]) => {
            if (Array.isArray(value) && value.length > 0) {
                const flatObjectsArray = value.map(obj => {
                    // Flatten nested arrays
                    return Object.fromEntries(
                        Object.entries(obj).flatMap(([k, v]) => 
                            Array.isArray(v) ? v.map((item, index) => [`${k}_${index+1}`, item]) : [[k, v]]
                        )
                    );
                });
    
                const ws = XLSX.utils.json_to_sheet(flatObjectsArray);
                XLSX.utils.book_append_sheet(workbook, ws, key);
            }
        });
    
        // Check if the workbook is not empty
        if (workbook.SheetNames.length > 0) {
            XLSX.writeFile(workbook, filename);
        } else {
            console.error('No data to write. The workbook is empty.');
        }
    };
    
    
    const generatePDF = async (patientData, userData) => {
        try {
            const functions = getFunctions();
            const generatePDF = httpsCallable(functions, 'generatePDF');
    
            const result = await generatePDF({ patientData, userData });
            const { data } = result;
    
            // Decode the base64 string
            const decodedData = atob(data.pdfBase64);
    
            // Convert the decoded string to an array of character codes
            const charCodes = decodedData.split('').map(c => c.charCodeAt(0));
    
            // Create a Blob from the character codes
            const blob = new Blob([new Uint8Array(charCodes)], { type: 'application/pdf' });
    
            // Get the current date
            const currentDate = new Date().toISOString().slice(0, 10);
    
            // Format the file name as "firstInitial_lastName_date.pdf"
            const firstInitial = patientData.firstName ? patientData.firstName.charAt(0).toUpperCase() : '';
            const lastName = patientData.lastName ? capitalizeName(patientData.lastName) : 'Unknown';
            const fileName = `${firstInitial}_${lastName}_${currentDate}.pdf`;
    
            // Create a URL for the Blob and download the PDF
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    
    
    const fetchUserData = async (uid) => {
        try {
            const userRef = doc(db, "users", uid);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                return userDoc.data();
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    

    return (
        <>
            <span className={styles.downloadText} onClick={() => setIsSubcollectionModalOpen(true)}>Download</span>
            
            {/* Modal for Subcollection Selection */}
            <Modal
                isOpen={isSubcollectionModalOpen}
                onRequestClose={() => setIsSubcollectionModalOpen(false)}
                className="confirmModal"
            >
            <button className='filesCloseButton' onClick={() => {setIsSubcollectionModalOpen(false)}}>X</button>
                <h2>Data Selection</h2>
                <h4>Please select the data you want downloaded.</h4>
                {Object.keys(selectedSubcollections).map(key => (
                    <div key={key}>
                        <input
                            type="checkbox"
                            className="custom-checkbox"
                            name={key}
                            checked={selectedSubcollections[key]}
                            onChange={handleCheckboxChange}
                        />
                        {key}
                    </div>
                ))}
                <button onClick={handleNextClick}>Next</button>
            </Modal>

            {/* Modal for Download Format Selection */}
            <Modal
                isOpen={isDownloadFormatModalOpen}
                onRequestClose={() => setIsDownloadFormatModalOpen(false)}
                className="confirmModal"
            >
                <button className='filesCloseButton' onClick={() => {setIsDownloadFormatModalOpen(false)}}>X</button>
                <h2>Download Format</h2>
                <h4>Please select the format you want the patient data in.</h4>
                <button onClick={() => handleDownload('xlsx')}>XLSX</button>
                <button onClick={() => handleDownload('pdf')}>PDF</button>
                <button onClick={handleBackClick}>Back</button>
            </Modal>
        </>
    );
}

export default PatientDataDownload;