import React, { useState, useEffect } from 'react';
import { auth, db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import PayerSelection from '../Payers/PayerSelection';
import useUID from '../General/useUID'
import { useNavigate } from 'react-router-dom';

function EditPatientPage({ handleSave, patientId, firstName, middleName = "", lastName, dob, gender, memberid, address1, address2, city, state, zip, tradingPartnerName, email, phone, handleShowEditPage, handleDelete, onClose }) {

  // Utility function to convert DOB format from MM/DD/YYYY to YYYY-MM-DD
  const convertDateFormat = (inputDate) => {
    const [month, day, year] = inputDate.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };
  const navigate = useNavigate();

  // Convert DOB to the correct format for the date input
  const formattedDOB = dob ? convertDateFormat(dob) : '';

  // Use currentData values or defaults to initialize secondary payer values
const initialPatientData = {
  patient: {
    firstName,
    middleName,
    lastName,
    dob: formattedDOB,
    gender,
    email: email || "",
    phone: phone || "",
    address: {
      address1,
      address2: address2 || '',
      city,
      state,
      zip,
    },
  },
  payers: {
    memberId: memberid,
    name: tradingPartnerName,
    secondaryPayer: {
      name: tradingPartnerName?.secondaryPayer?.name || "", // Initialize from current data if available
      memberId: memberid?.secondaryPayer?.memberId || "",   // Initialize from current data if available
    },
  },
};

// Initialize states for secondary payer section
const [payerData, setPayerData] = useState({
  memberId: memberid || "",
  name: tradingPartnerName || "",
});

const [showSecondaryPayer, setShowSecondaryPayer] = useState(
  !!initialPatientData.payers.secondaryPayer?.name
);

const [secondaryMemberId, setSecondaryMemberId] = useState(
  initialPatientData.payers.secondaryPayer?.memberId || ""
);

const [selectedSecondaryPayer, setSelectedSecondaryPayer] = useState(
  initialPatientData.payers.secondaryPayer || null
);



  
  const [patientData, setPatientData] = useState(initialPatientData);
  const [errorMsg, setErrorMsg] = useState(null);
  const [selectedPayer, setSelectedPayer] = useState(null); // Renamed for clarity
  const [uid] = useUID();

  const formatDateToDb = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    return `${month}/${day}/${year}`;
  };


  const getValidationErrors = () => {
    if (!patientData?.patient) return "Patient data is missing or incomplete.";

    const { firstName, lastName, gender, dob, address } = patientData.patient;

    if (!firstName || !lastName || !gender || !dob) return "Required fields (First Name, Last Name, Gender, Date of Birth) are missing.";

    if (address?.state && address.state.length !== 2) return "State must be a 2-letter abbreviation.";

    const zip = address?.zip ? String(address.zip) : "";
    // Adjust ZIP code validation based on the environment
      if (zip && zip.trim().length !== 5) return "ZIP code must be 5 digits in production mode.";
    
  
    if (showSecondaryPayer && (!selectedSecondaryPayer || !secondaryMemberId))
      return "Both Secondary Payer and Secondary Member ID must be provided.";
  
    return null; // No errors found
  };

  const handlePayerSelect = (payer) => {
    console.log("Selected payer:", payer);
    if (!payer) {
      console.error('No payer selected');
      setSelectedPayer(null); // Reset the selected payer if none is selected
      return;
    }
    setSelectedPayer(payer); // Update the selected payer
  };

  const handleInputChange = (event, field, subfield = null, isPayerData = false) => {
    const value = event.target.value || "";
    if (isPayerData) {
      setPayerData(prevData => ({ ...prevData, [field]: value }));
    } else {
      setPatientData(prevData => {
        const updatedData = { ...prevData };
        if (subfield) {
          if (!updatedData.patient[field]) {
            updatedData.patient[field] = {};
          }
          updatedData.patient[field][subfield] = value;
        } else {
          updatedData.patient[field] = value;
        }
        return updatedData;
      });
    }
  };

  const saveEditedPatientData = async () => {
    try {
      const validationErrors = getValidationErrors();
      if (validationErrors) {
        console.error("Validation Error:", validationErrors);
        setErrorMsg(validationErrors);
        return;
      }
  
      const patientDataRef = doc(db, "patients", uid, "patientData", patientId);
  
      // Convert DOB format if it exists
      if (patientData.patient && patientData.patient.dob) {
        patientData.patient.dob = formatDateToDb(patientData.patient.dob);
      }
  
      const currentPatientData = await getDoc(patientDataRef);
      const currentData = currentPatientData.data();
  
      // Preserve clinicToken if it exists
      const clinicToken = currentData.patient?.clinicToken;
  
      // Construct the updated patient data
      const updatedPatientData = {
        ...currentData,
        patient: {
          ...currentData.patient,
          ...patientData.patient,
          ...(clinicToken && { clinicToken }), // Retain clinicToken if it exists
        },
        payers: {
          // Primary payer information
          ...payerData,
          ...selectedPayer, // Merge primary payer if selected
          // Secondary payer information, if provided
          ...(selectedSecondaryPayer && secondaryMemberId && {
            secondaryPayer: {
              name: selectedSecondaryPayer.name || '',
              memberId: secondaryMemberId || '',
              ...selectedSecondaryPayer,
            }
          })
        },
      };
  
      // Save the updated data to Firestore
      await updateDoc(patientDataRef, updatedPatientData);
  
      handleSave(updatedPatientData); // Trigger save callback with updated data
      navigate(`/patients/${patientId}`);
      onClose();
    } catch (error) {
      console.error("Error updating patient data:", error);
      setErrorMsg("An error occurred while saving patient data.");
    }
  };
  

  // Function to handle secondary payer selection
  const handleSecondaryPayerSelect = (payer) => {
    setSelectedSecondaryPayer(payer);
  };
  

  return (
    <div className="popupContainer">
      <div className="popupContent">

        <div className="cancelButton">
          <button onClick={onClose} className="filesCloseButton">X</button>
        </div>

        <div className="topBar content-center">
          {/* <span className="editText" onClick={handleShowEditPage}>Cancel</span> */}
          <span className="deleteText" onClick={handleDelete}>Delete</span>
        </div>

        <form
          className="editPatient"
          onSubmit={(event) => {
            event.preventDefault();
            saveEditedPatientData();
          }}
        >
          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                value={patientData.patient.firstName || ""}
                onChange={(event) => handleInputChange(event, 'firstName')}
              />
            </div>

            <div className="input-field">
              <label htmlFor="middleName">Middle Name</label>
              <input
                type="text"
                value={patientData.patient.middleName || ""}
                onChange={(event) => handleInputChange(event, 'middleName')}
              />
            </div>

            <div className="input-field">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                value={patientData.patient.lastName || ""}
                onChange={(event) => handleInputChange(event, 'lastName')}
              />
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="dob">Date of Birth</label>
              <input
                type="date"
                value={patientData.patient.dob || ""} // Use the state value
                onChange={(event) => handleInputChange(event, 'dob')}
              />
            </div>

            {/* Gender Buttons */}
            <div className="input-field">
              <div className="gender-buttons">
                <button
                  type="button"
                  className={`gender-button ${patientData.patient.gender === "M" ? "selected" : ""}`}
                  onClick={() => handleInputChange({ target: { value: "M" } }, 'gender')}
                >
                  M
                </button>
                <button
                  type="button"
                  className={`gender-button ${patientData.patient.gender === "F" ? "selected" : ""}`}
                  onClick={() => handleInputChange({ target: { value: "F" } }, 'gender')}
                >
                  F
                </button>
              </div>
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="address1">Address 1</label>
              <input
                type="text"
                value={patientData.patient.address.address1 || ""}
                onChange={(event) => handleInputChange(event, 'address', 'address1')}
              />
            </div>

            <div className="input-field">
              <label htmlFor="address2">Address 2</label>
              <input
                type="text"
                value={patientData.patient.address.address2 || ""}
                onChange={(event) => handleInputChange(event, 'address', 'address2')}
              />
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="city">City</label>
              <input
                type="text"
                value={patientData.patient.address.city || ""}
                onChange={(event) => handleInputChange(event, 'address', 'city')}
              />
            </div>


            <div className="input-group-row">
              <div className="input-field">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  value={patientData.patient.address.state || ""}
                  onChange={(event) => handleInputChange(event, 'address', 'state')}
                />
              </div>

              <div className="input-field">
                <label htmlFor="zip">Zip</label>
                <input
                  type="text"
                  value={patientData.patient.address.zip || ""}
                  onChange={(event) => handleInputChange(event, 'address', 'zip')}
                />
              </div>
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                value={patientData.patient.email || ""}
                onChange={(event) => handleInputChange(event, 'email')}
              />
            </div>
            <div className="input-field">
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                value={patientData.patient.phone || ""}
                onChange={(event) => handleInputChange(event, 'phone')}
              />
            </div>
          </div>

          <div className="input-group-row">
            <div className="input-field">
              <label htmlFor="phone">MemberId</label>
              <input
                type="tel"
                value={payerData.memberId}
                onChange={(event) => {
                  const sanitizedValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
                  handleInputChange({ target: { value: sanitizedValue } }, 'memberId', null, true);
                }}
              />

            </div>
          </div>

          {/* Payer Selection */}
          <div className="edit-group-payerSelection content-center">
            <PayerSelection
              mode={'claims'}
              TradingPartnerName={payerData.name}
              onSelect={handlePayerSelect}
              required
            />
          </div>

          {!showSecondaryPayer && (
            <div className="input-group-row content-center">
              <button
                type="button"
                className="secondaryButton"
                onClick={() => setShowSecondaryPayer(true)}
              >
                + Add Secondary Payer
              </button>
            </div>
          )}

          {showSecondaryPayer && (
            <div>
              <div className="input-group-row">
                <div className="input-field">
                  <label htmlFor="secondaryMemberId">Secondary Member ID</label>
                  <input
                    type="text"
                    id="secondaryMemberId"
                    value={secondaryMemberId}
                    onChange={(event) => {
                      const sanitizedValue = event.target.value.replace(
                        /[^a-zA-Z0-9]/g,
                        ""
                      ); // Sanitize input to allow alphanumeric only
                      setSecondaryMemberId(sanitizedValue);
                    }}
                  />
                </div>
              </div>
              <div className="edit-group-payerSelection content-center">
              <PayerSelection onSelect={handleSecondaryPayerSelect} mode={'claims'} />

              </div>
            </div>
          )}


          {errorMsg && <div className="error-message">{errorMsg}</div>}
          <div className="input-group-row">
            <div className="input-field">
              <button type="submit" className="done-button">
                Done
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditPatientPage;
