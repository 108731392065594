import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import PatientList from "./PatientList";
import "./PatientFiles.modules.css";
import Topbar from "../General/Topbar";
import PatientView from "./PatientView";
import FolderControls from "./FolderControls";

function PatientFiles() {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState("patientData");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false); // Timer state
  const { patientIdfromURL } = useParams();
  const scrollingTableRef = useRef(null);

  const handleSelectPatient = (patient) => {
    setSelectedPatient(patient);
  };

  const handleStartTimer = () => {
    setIsTimerRunning(true); // Start the timer
    console.log("timuig")
  };

  // Function to toggle dataUpdated state
  const handleDataUpdated = () => {
    setDataUpdated((prevState) => !prevState);
  };

  return (
    <div className="background">
      <Topbar />
      <div className="content">
        <div className="patientWindow">
          <PatientView
            patientSelected={selectedPatient}
            onDataUpdated={handleDataUpdated}
            isTimerRunning={isTimerRunning} // Pass timer state to PatientView
          />
        </div>
        <div className="folderControlsContainer">
          <FolderControls
            onFolderChange={setSelectedFolder}
            selectedFolder={selectedFolder}
          />
        </div>
        <div className="patientViewBar" ref={scrollingTableRef}>
          <PatientList
            onSelectPatient={handleSelectPatient}
            selectedFolder={selectedFolder}
            selectedPatient={selectedPatient}
            patientIdfromURL={patientIdfromURL}
            scrollingTableRef={scrollingTableRef}
            dataUpdated={dataUpdated}
            onStartTimer={handleStartTimer} // Pass start timer function to PatientList
          />
        </div>
      </div>
    </div>
  );
}

export default PatientFiles;
