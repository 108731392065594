// ProviderContext.js
import React, { createContext, useState, useEffect } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

const ProviderContext = createContext();

export const useProviderContext = () => React.useContext(ProviderContext);

export const ProviderProvider = ({ children, uid }) => {
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    if (!uid) return;
    fetchProviders();
  }, [uid]);

  const fetchProviders = async () => {
    const providersRef = collection(db, `users/${uid}/providers`);
    const querySnapshot = await getDocs(providersRef);
    const providersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setProviders(providersData);
  };

  const updateProviders = (newProviders) => {
    setProviders(newProviders);
};

return (
    <ProviderContext.Provider value={{ providers, updateProviders }}>
        {children}
    </ProviderContext.Provider>
);
};


