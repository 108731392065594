import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';

function DexcomAuthSuccess() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  // Extract Dexcom-specific parameters
  const code = query.get('code');  // Authorization code from Dexcom
  const state = query.get('state'); // State containing uid and patientId

  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  // Split state to retrieve uid and patientId
  const [uid, patientId] = state ? state.split('-') : [null, null];

  const handleDexcomTokenExchange = async () => {
    console.log("Starting Dexcom token exchange process");

    if (!code) {
      setError("Authorization code is missing for Dexcom.");
      console.error("Authorization code is missing for Dexcom.");
      return;
    }

    const functions = getFunctions();
    const dexcomTokenExchange = httpsCallable(functions, 'dexcomTokenExchange');

    try {
      console.log("Calling Firebase function 'dexcomTokenExchange' with code:", code);
      // Call Firebase function to exchange code for access token
      const result = await dexcomTokenExchange({ code, uid, patientId });
      console.log("Firebase function 'dexcomTokenExchange' returned result:", result);

      if (result.data.status === 'success') {
        setIsSuccess(true);
        console.log("Access token successfully obtained from Dexcom.");
        sessionStorage.removeItem('dexcom_auth_state');
      }
    } catch (error) {
      console.error('Error exchanging Dexcom token:', error);
      setError(error.message);
    }
  };

  // Trigger token exchange when component mounts
  useEffect(() => {
    console.log("DexcomAuthSuccess component mounted");
    console.log("Received authorization code:", code);
    console.log("Received state:", state);

    handleDexcomTokenExchange().catch(error => {
      console.error('Error exchanging Dexcom token:', error);
      setError(error.message);
    });
  }, [code, state]);

  return (
    <div className='content-center'>
      <h1>Congratulations! Dexcom Authentication was Successful</h1>
      <p>Authorization Code: {code}</p>
      {state && <p>State: {state}</p>}
      {error && <p style={{ color: 'red' }}>Error: {error}</p>}
      {isSuccess && <p style={{ color: 'green' }}>Access token obtained successfully!</p>}
    </div>
  );
}

export default DexcomAuthSuccess;
